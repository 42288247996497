import Vue from "vue";
import VuetifyGoogleAutocomplete from "vuetify-google-autocomplete";

//TODO: remove vuetify-google-autocomplete and load the library directly in GAddressInput.vue
/**
 * @deprecated This is only used for initializing the Google Maps Places API
 * The initialized google object is available globally after this is called.
 */
export default function () {
  Vue.use(VuetifyGoogleAutocomplete, {
    language: "DE", // Optional
    installComponents: false, // Optional (default: true) - false, if you want to locally install components
    vueGoogleMapsCompatibility: false, // Optional (default: false)
    apiKey: process.env.VUE_APP_GOOGLE_API_KEY,
  });
}
