import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "@/store/types";
import { PageAction, PageActions } from "@/types/general/actions";

const state: PageActions = {
  actions: [] as PageAction[],
  title: "",
  selectionCount: 0,
};

const getters: GetterTree<PageActions, RootState> = {};

const actions: ActionTree<PageActions, RootState> = {
  setPageTitle({ commit }, title: string) {
    // change document title
    document.title = title;
    // change page title (used by toolbar)
    commit("setTitle", title);
  },
};

const mutations: MutationTree<PageActions> = {
  setActions(state, pageActions: PageAction[]) {
    state.actions = pageActions;
  },
  setTitle(state, title: string) {
    state.title = title;
  },
  setSelectionCount(state, selectionCount: number) {
    state.selectionCount = selectionCount;
  },
};

const pageActions: Module<PageActions, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

/**
 * `pageActions` stores menu buttons and their actions available to the user in the page menu.
 *
 */
export default pageActions;
