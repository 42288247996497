import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import Vue from "vue";

import { delay, showToastWarning } from "@/plugins/utilities";
import Router from "@/router";
import AuthResult from "@/types/user-management/auth-result";
import { refreshAccessToken } from "@/api/server";

// Whether the refresh token is currently being refreshed
let isRefreshingAccessToken = false;

const repeatFailedRequest = async function (reqConfig: AxiosRequestConfig) {
  try {
    const res = await axios(reqConfig);
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
};

/**
 * Sets the session Access Token
 * @param authResult Access Token, Refresh Token
 * @returns void
 * @alternative Write dedicated .ts TokenService
 */
const setUserTokens = function (authResult: AuthResult): void {
  axios.defaults.headers.common.Authorization = `Bearer ${authResult.accessToken}`;
  Vue.prototype.$session.set("accessToken", authResult.accessToken);
  Vue.prototype.$session.set("refreshToken", authResult.refreshToken);
  Vue.prototype.$session.start();
};

/**
 * Executes helper functions to register Axios interceptors
 * -> Adds Auth token to every request being made
 * -> In case of failed 401 request, the refreshToken is used to log in again.
 * The request which previously failed is retried.
 */
export default function () {
  axios.interceptors.response.use(
    (res) => res,
    async (err) => {
      const errResp = err.response as AxiosResponse;
      //No web request error, reject -> back to caller
      if (!errResp) return Promise.reject(err);

      //Try to get the request config from the failed request
      let reqConfig = {} as AxiosRequestConfig;
      if (errResp) reqConfig = errResp.config;

      //TODO: Resolve accessToken loaded 3 times, once for each interceptor
      // this is not a problem, but it is not nice either
      // refreshToken is once fetched by the login page, then by the router.beforeEach & here once again

      if (errResp && errResp.status === 401) {
        if (reqConfig.url === `${process.env.VUE_APP_ACCOUNT_API}/login`) {
          //If the failed request was a login request, maybe credentials are incorrect -> back to caller
          return Promise.reject(err);
        } else if (
          reqConfig.url === `${process.env.VUE_APP_ACCOUNT_API}/accessToken`
        ) {
          //If the failed request was a refreshToken request, maybe the refreshToken is incorrect -> back to caller
          return Promise.reject(err);
        } else {
          //Set a counter on the request config to prevent infinite loops
          err.config.retryCount = err.config.retryCount || 0;
          err.config.retryCount++;
          if (err.config.retryCount > 3) return Promise.reject(err);

          //Unauthorized
          const refreshToken = Vue.prototype.$session.get("refreshToken");
          if (refreshToken && !isRefreshingAccessToken) {
            //If accessToken is already being refreshed, do not refresh again
            isRefreshingAccessToken = true;

            //Refresh token is not valid, try to log in again
            let res = {} as AuthResult;
            try {
              res = await refreshAccessToken(refreshToken);
              setUserTokens(res);
            } catch (err) {
              //Refresh failed, log out
              Vue.prototype.$session.destroy();
              showToastWarning("Bitte erneut anmelden");
              await Router.push({ name: "LoginView" });
              return Promise.reject(err);
            }

            isRefreshingAccessToken = false;
          }
          //wait shortly to prevent spamming the server
          //TODO: Find a better solution, only delay the which are waiting for the refresh token
          return delay(150).then(
            async () => await repeatFailedRequest(reqConfig)
          );
        }
      } else {
        //In case of other response status - the user is logged in, but the server rejected the request?
        switch (errResp.status) {
          case 400:
            if (
              errResp.data &&
              errResp.data.name === "SequelizeConnectionError"
            )
              showToastWarning("Keine Verbindung zur Datenbank möglich.");
            break;
          case 403:
            //Route is not allowed for the user
            showToastWarning(
              `Unzureichende Berechtigungen für ${reqConfig.url}`
            );
            break;
          default:
            return Promise.reject(err);
        }
      }
    }
  );

  //Add the JWT to every request
  axios.interceptors.request.use(
    (config) => {
      const accessToken = Vue.prototype.$session.get("accessToken");
      if (config.headers && accessToken)
        config.headers.Authorization = `Bearer ${accessToken}`;

      //if request is a delete and send to the asset api, add "empty" body to request
      //otherwise the request will be rejected by the server due to a bug in falcon3 API framework
      if (
        config.method === "delete" &&
        config.url &&
        config.url.startsWith(process.env.VUE_APP_ASSET_API || "")
      ) {
        config.data = {};
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
}

export { setUserTokens };
