
import { Component, Prop, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import { RouteConfig } from "vue-router";
import { Permission } from "@/types/user-management/access-policy";
import DarkModeToggle from "@/components/misc/DarkModeToggle.vue";
import CompanyLogo from "@/components/media/CompanyLogo.vue";

/**
 * Navigation Drawer renders the navigation drawer on the left side of the screen.
 * Routes are rendered dynamically based on the routes defined in the router.
 * If a user does not have permissions, the route will not be rendered. (see ability.ts)
 *
 * Type errors for route.meta in v-for are a webstorm bug, see https://youtrack.jetbrains.com/issue/WEB-50459
 */
@Component({
  components: { CompanyLogo, DarkModeToggle },
  computed: {
    ...mapState("company", {
      companyLogo: "logo",
      companyName: "name",
      companyId: "id",
    }),
    ...mapState("user", ["AccessLevel"]),
  },
})
export default class ZNavigationDrawer extends Vue {
  @Prop({ default: true })
  visible!: boolean;

  readonly appName = process.env.VUE_APP_NAME || "Application";
  readonly appVersion = process.env.VUE_APP_VERSION || "0.0.0";

  companyLogo?: string;
  companyId?: string;
  loading = false;

  get routes() {
    // $router.options is a vue-router internal property returning RouteConfig[]
    // $router.getRoutes is returning RouteRecordPublic[]
    return this.$router.options.routes;
  }

  /* Methods */
  changeVisibility(visible: boolean) {
    this.$emit("change-visibility", visible);
  }

  //Returns category title if the route has a category and is the first route in the category
  hasSubheadline(currRoute: RouteConfig) {
    if (!this.routes || !currRoute.meta || !currRoute.meta.category) {
      return false;
    }

    const category = currRoute.meta.category;
    const routesInCategory = this.routes.filter(
      (route) => route.meta && route.meta.category === category
    );

    let firstAccessibleRoute = null;
    for (const route of routesInCategory) {
      if (this.canRead(route)) {
        if (!firstAccessibleRoute) {
          firstAccessibleRoute = route;
        }
        if (route.meta?.title === currRoute.meta.title) {
          return route === firstAccessibleRoute;
        }
      }
    }
    return false;
  }

  canRead(item: RouteConfig): boolean {
    return this.$can(Permission.read, item.path);
  }

  async logout() {
    this.loading = true;
    await this.$store
      .dispatch("user/logout")
      .then(() => {
        this.$router.push({ path: "/login" });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  mounted() {
    if (!this.companyId) {
      const companyID = this.$session.get("companyID");
      if (companyID) this.$store.dispatch("company/fetchById", companyID);
    }
  }
}
