
import { Vue } from "vue-property-decorator";
import ZAppBar from "@/components/ZAppBar.vue";
import ZNavigationDrawer from "@/components/ZNavigationDrawer.vue";
import { mapState } from "vuex";
import Component from "vue-class-component";
import { PageAction } from "@/types/general/actions";

@Component({
  components: { ZAppBar, ZNavigationDrawer },
  computed: {
    ...mapState("pageActions", {
      pageActions: "actions",
      selectionCount: "selectionCount",
      pageTitle: "title", //can be used to set the title of the page manually
    }),
  },
})
export default class App extends Vue {
  /* Computed */
  pageActions?: PageAction[];
  selectionCount?: number;
  pageTitle?: string;

  /* Static data */
  /* Dynamic data */
  navIsVisible = true;
  permissionReady = false;

  /**
   * The title of the page. If the title is not set manually,
   * the title of the current route's meta is used.
   */
  get title() {
    if (this.pageTitle) {
      return this.pageTitle;
    } else if (this.$route.meta) {
      return this.$route.meta.title;
    }
    return "";
  }

  get navSettingIsVisible() {
    const v = this.$route.meta?.showNav && this.permissionReady;
    if (v !== undefined) {
      return v;
    } else {
      return true;
    }
  }

  /* Lifecycle */
  mounted() {
    // render the navigation drawer only after the permission are loaded (see ability.ts)
    this.$ability.on("updated", () => {
      this.permissionReady = true;
    });
  }

  /* Event handlers */

  onChangeNavigationDrawerVisibility(visible: boolean) {
    this.navIsVisible = visible;
  }
}
