import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { JobStore, RootState } from "@/store/types";
import { JobTask } from "@/types/jobs/Job";
import { fetchJobTasks } from "@/api/server";

const state: JobStore = {
  tasks: [] as JobTask[],
};

const getters: GetterTree<JobStore, RootState> = {
  tasks: (state) => state.tasks,
};

const actions: ActionTree<JobStore, RootState> = {
  fetchJobTasks({ commit }) {
    if (state.tasks.length > 0) return;
    fetchJobTasks().then((jobTasks) => {
      commit("setJobTasks", jobTasks);
    });
  },
};

const mutations: MutationTree<JobStore> = {
  setJobTasks(state, jobTasks: JobTask[]) {
    state.tasks = jobTasks;
  },
};

const JobStore: Module<JobStore, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

/**
 * `JobStore` stores menu buttons and their actions available to the user in the page menu.
 *
 */
export default JobStore;
