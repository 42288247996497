
import { Component, Prop, Vue } from "vue-property-decorator";
import { Permission } from "@/types/user-management/access-policy";
import { substitudePathId } from "@/plugins/utilities";
import { PageAction } from "@/types/general/actions";

//TODO: generate pageTitle from current route meta
@Component
export default class ZAppBar extends Vue {
  /* Props */
  @Prop({ default: () => [] })
  actions?: PageAction[];

  @Prop({ default: () => 0 })
  selectedItemsCount!: number;

  @Prop({ default: () => "" })
  title!: string;

  /* Dynamic data */
  loading = false;

  isActionEnabled(action: PageAction) {
    //substitute uuid in path with :id
    const path = substitudePathId(this.$route.params, this.$route.path);

    if (typeof action.action === "function" && action.icon) {
      switch (action.icon) {
        case "mdi-plus":
          return this.$can(Permission.create, path);
        case "mdi-delete":
          return this.$can(Permission.delete, path);
        default:
          return true;
      }
    }
    return false;
  }
}
