import PreEmptyFillingLevelStat from "@/types/report/pre-empty-filling-level-stat";
import KeepaliveLog from "@/types/asset/keepalive-log";
import axios, { AxiosResponse } from "axios";
import AccessLevel from "@/types/access-level";
import ApiKey from "@/types/api-key";
import Asset, { AssetFilter } from "@/types/asset/asset";
import AssetType from "@/types/asset/asset-type";
import AuthResult from "@/types/user-management/auth-result";
import Company from "@/types/company";
import Device, { DeviceFilter } from "@/types/device";
import EmptyDetection from "@/types/asset/empty-detection";
import Fence, { AccessTime, FenceAssetLog, LatLng } from "@/types/fence/fence";
import FenceType from "@/types/fence/fence-type";
import FillingLog from "@/types/asset/filling-log";
import LogoutResult from "@/types/user-management/logout-result";
import Page, { PageOption } from "@/types/page";
import { Page as SpringPage } from "@/types/general/SpringPagination";
import Property from "@/types/asset/property";
import TheftDetection from "@/types/asset/theft-detection";
import TrackingLog from "@/types/asset/tracking-log";
import UseCase from "@/types/asset/use-case";
import AssetCondition from "@/types/asset/asset-condition";
import AssetCleaning from "@/types/asset/asset-cleaning";
import AssetContentMaterial from "@/types/asset/asset-content-material";
import AssetTypeLight from "@/types/asset/asset-type-light";
import AssetVolume from "@/types/asset/asset-volume";
import { Moment } from "moment";
import ContentMaterialLogs from "@/types/asset/content-material-logs";
import PreEmptyFillingLevel from "@/types/report/pre-empty-filling-level";
import { UserAccount } from "@/types/user-management/user-account";
import { RbacModule, Role, Route } from "@/types/user-management/access-policy";
import { AssetTag, FenceTag, Filter } from "@/types/general/general";
import {
  Vehicle,
  VehicleLoadCapacity,
  VehicleModel,
  VehiclePart,
  VehiclePartManufacturer,
  VehicleType,
} from "@/types/recycling-manager/vehicle";
import { Department } from "@/types/recycling-manager/department";
import { Column, WorkingHour } from "@/types/recycling-manager/column";
import { Duty } from "@/types/recycling-manager/duty";
import { HasId } from "@/types/vue/mixins";
import { camelize } from "@/plugins/utilities";
import {
  CombinedScanLog,
  NfcScanLog,
  QRScanInfo,
  QRScanLog,
  Report,
  ReportDTO,
  ReportFilter,
  ReportIncident,
} from "@/types/report";
import { AvalCatalog, AvalCatalogEntry } from "@/types/aval/aval_catalog";
import {
  LocationContainer,
  LocationContainerContent,
} from "@/types/asset/location-container";
import { ContactPerson } from "@/types/recycling-manager/contact-person";
import { Location } from "@/types/location/location";
import { LocationFilter } from "@/types/location/locationFilter";
import { CreateMultipleJobsRequest } from "@/types/jobs/JobCreation";
import { Job, JobFilter, JobTask, JobsBySubject } from "@/types/jobs/Job";

/* Authentication */

export function login(mail: string, password: string): Promise<AuthResult> {
  const data = { mail, password };
  return axios
    .post(`${process.env.VUE_APP_ACCOUNT_API}/login`, data)
    .then((response) => response.data);
}

export function logout(refreshToken: string): Promise<LogoutResult> {
  const data = { refreshToken };
  return axios
    .post(`${process.env.VUE_APP_ACCOUNT_API}/logout`, data)
    .then((response) => response.data);
}

export function refreshAccessToken(refreshToken: string): Promise<AuthResult> {
  const data = { refreshToken };
  return axios
    .post(`${process.env.VUE_APP_ACCOUNT_API}/accessToken`, data)
    .then((response) => response.data);
}

/* Access Policy stuff */
export function fetchAccessLevels(): Promise<AccessLevel[]> {
  return axios
    .get(`${process.env.VUE_APP_ACCOUNT_API}/accessLevels`)
    .then((response) => response.data);
}

export function fetchRoles(): Promise<Role[]> {
  return axios
    .get(`${process.env.VUE_APP_ACCOUNT_API}/roles`)
    .then((response) => response.data);
}

export function fetchRole(id: string): Promise<Role> {
  return axios
    .get(`${process.env.VUE_APP_ACCOUNT_API}/roles/${id}`)
    .then((response) => response.data);
}

export function deleteRole(id: string): Promise<Role> {
  return axios
    .delete(`${process.env.VUE_APP_ACCOUNT_API}/roles/${id}`)
    .then((response) => response.data);
}

export function createRole(role: Partial<Role>): Promise<Role> {
  return axios
    .post(`${process.env.VUE_APP_ACCOUNT_API}/roles`, role)
    .then((response) => response.data);
}

export function editRole(role: Partial<Role>): Promise<Role> {
  return axios
    .put(`${process.env.VUE_APP_ACCOUNT_API}/roles/${role.id}`, role)
    .then((response) => response.data);
}

export function fetchRoutes(): Promise<Route[]> {
  return axios
    .get(`${process.env.VUE_APP_ACCOUNT_API}/routes`)
    .then((response) => response.data);
}

export function fetchApiRoutes(): Promise<Route[]> {
  return axios
    .get(`${process.env.VUE_APP_ACCOUNT_API}/routes/apis`)
    .then((response) => response.data);
}

export function createRoute(route: Route): Promise<Route> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(route);
    }, 1000);
  });
}

export function editRoute(route: Route): Promise<Route> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(route);
    }, 1000);
  });
}

/* Account */
export function fetchAccountById(id: string): Promise<UserAccount> {
  return axios
    .get(`${process.env.VUE_APP_ACCOUNT_API}/users/${id}`)
    .then((response) => response.data);
}

export function createAccount(
  account: UserAccount
): Promise<Partial<UserAccount>> {
  return axios
    .post(`${process.env.VUE_APP_ACCOUNT_API}/users`, account)
    .then((response) => response.data);
}

export function editAccount(
  id: string,
  data: Partial<UserAccount>
): Promise<UserAccount> {
  return axios
    .put(`${process.env.VUE_APP_ACCOUNT_API}/users/${id}`, data)
    .then((response) => response.data);
}

export function fetchPersonalAccount(): Promise<UserAccount> {
  return axios
    .get(`${process.env.VUE_APP_ACCOUNT_API}/users/me`)
    .then((response) => response.data);
}

export function fetchAccounts(): Promise<UserAccount[]> {
  return axios
    .get(`${process.env.VUE_APP_ACCOUNT_API}/users`)
    .then((response) => response.data);
}

export function removeAccount(account: UserAccount): Promise<string> {
  return axios
    .delete(`${process.env.VUE_APP_ACCOUNT_API}/users/${account.id}`)
    .then((response) => response.data);
}

export function fetchModules(): Promise<RbacModule[]> {
  return axios
    .get(`${process.env.VUE_APP_ACCOUNT_API}/modules`)
    .then((response) => response.data);
}

export function fetchModule(id: string): Promise<RbacModule> {
  return axios
    .get(`${process.env.VUE_APP_ACCOUNT_API}/modules/${id}`)
    .then((response) => response.data);
}

export function createModule(module: RbacModule): Promise<RbacModule> {
  return axios
    .post(`${process.env.VUE_APP_ACCOUNT_API}/modules`, module)
    .then((response) => response.data);
}

export function editModule(module: Partial<RbacModule>): Promise<RbacModule> {
  return axios
    .put(`${process.env.VUE_APP_ACCOUNT_API}/modules/${module.id}`, module)
    .then((response) => response.data);
}

export function deleteModule(id: string): Promise<AxiosResponse> {
  return axios.delete(`${process.env.VUE_APP_ACCOUNT_API}/modules/${id}`);
}

/* API key */

export function fetchApiKeys(): Promise<ApiKey[]> {
  return axios
    .get(`${process.env.VUE_APP_ZCLOUD_API}/apikeys`)
    .then((response) => response.data.data);
}

/* Asset */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createAsset(data: Partial<Asset>): Promise<Asset> {
  return axios
    .post(`${process.env.VUE_APP_ASSET_API}/assets`, data)
    .then((response) => response.data.data);
}

export function removeAsset(account: Asset): Promise<number> {
  return axios
    .delete(`${process.env.VUE_APP_ASSET_API}/assets/${account.id}`)
    .then((response) => response.status);
}

export function editAsset(asset: Asset): Promise<number> {
  if (asset.template_name) asset.template_name = asset.template_name.trim();
  const id = asset.id;
  const data = { ...asset } as Partial<Asset>;
  delete data.id;

  return axios
    .put(`${process.env.VUE_APP_ASSET_API}/assets/${id}`, data)
    .then((response) => response.status);
}

export function fetchPreEmptyFillingLevel(
  rangeStart: number,
  rangeEnd: number
): Promise<PreEmptyFillingLevel[]> {
  const params = new URLSearchParams();
  params.append("time_range", `${rangeStart}${rangeEnd}`);

  const query = params.toString();

  return axios
    .get(
      `${process.env.VUE_APP_ASSET_API}/assets-pre-empty-filling-level?${query}`
    )
    .then((response) => response.data.data);
}

export function fetchPreEmptyFillingLevelStat(
  rangeStart: number,
  rangeEnd: number
): Promise<PreEmptyFillingLevelStat[]> {
  const params = new URLSearchParams();
  params.append("time_range", `${rangeStart}${rangeEnd}`);

  const query = params.toString();

  return axios
    .get(
      `${process.env.VUE_APP_ASSET_API}/assets-pre-empty-filling-level-stat?${query}`
    )
    .then((response) => response.data.data);
}

export function fetchAsset(id: string): Promise<Asset> {
  return axios
    .get(`${process.env.VUE_APP_ASSET_API}/assets/${id}`)
    .then((response) => response.data.data);
}

export function fetchContentMaterialLogs(
  fenceId: string
): Promise<ContentMaterialLogs[]> {
  return axios
    .get(
      `${process.env.VUE_APP_ASSET_API}/fences/${fenceId}/content-material-logs`
    )
    .then((response) => response.data.data);
}

export function fetchAssets(filter: AssetFilter): Promise<Page<Asset>> {
  return axios
    .get(`${process.env.VUE_APP_ASSET_API}/assets?${filter.toQueryString()}`)
    .then((response) => response.data);
}

export function fetchAssetEmptyDetections(
  id: string
): Promise<EmptyDetection[]> {
  return axios
    .get(`${process.env.VUE_APP_ASSET_API}/assets/${id}/empty-detections`)
    .then((response) => response.data.data);
}

export function fetchAssetFillingLogs(
  id: string,
  rangeStart: number,
  rangeEnd: number
): Promise<FillingLog[]> {
  const params = new URLSearchParams();
  params.append("sort", "created_at");
  params.append("time_range", `${rangeStart},${rangeEnd}`);

  const query = params.toString();

  return axios
    .get(`${process.env.VUE_APP_ASSET_API}/assets/${id}/filling-logs?${query}`)
    .then((response) => response.data.data);
}

export function fetchAssetTheftDetections(
  id: string
): Promise<TheftDetection[]> {
  return axios
    .get(`${process.env.VUE_APP_ASSET_API}/assets/${id}/theft-detections`)
    .then((response) => response.data.data);
}

export function editTheftDetection(
  id: string,
  checkedOutAt: Moment
): Promise<number> {
  const data = {
    checked_out_at: checkedOutAt,
  };

  return axios
    .put(`${process.env.VUE_APP_ASSET_API}/assets/${id}/theft-detections`, data)
    .then((response) => response.status);
}

export function fetchAssetTrackingLogs(id: string): Promise<TrackingLog[]> {
  return axios
    .get(
      `${process.env.VUE_APP_ASSET_API}/assets/${id}/tracking-logs?sort=created_at-`
    )
    .then((response) => response.data.data);
}

export function fetchAssetKeepaliveLogs(id: string): Promise<KeepaliveLog[]> {
  return axios
    .get(
      `${process.env.VUE_APP_ASSET_API}/assets/${id}/keepalive-logs?sort=created_at-`
    )
    .then((response) => response.data.data);
}

/* Asset type */

export function fetchAssetTypes(): Promise<AssetType[]> {
  return axios
    .get(`${process.env.VUE_APP_ASSET_API}/asset-types`)
    .then((response) => response.data.data);
}

/* Asset type Light */

export function fetchAssetTypesFlat(): Promise<AssetTypeLight[]> {
  return axios
    .get(`${process.env.VUE_APP_ASSET_API}/asset-types-flat`)
    .then((response) => response.data.data);
}

/* Company */

export function createCompany(company: Company): Promise<Company> {
  return axios
    .post(`${process.env.VUE_APP_ACCOUNT_API}/companies`, company)
    .then(async (response) => {
      const data = { companyID: response.data.id };
      await axios.put(`${process.env.VUE_APP_ZCLOUD_API}/apikeys`, data);
      return response.data;
    });
}

export function editCompany(company: Partial<Company>): Promise<Company> {
  return axios
    .put(`${process.env.VUE_APP_ACCOUNT_API}/companies/${company.id}`, company)
    .then((response) => response.data);
}

export function fetchCompanyById(id: string): Promise<Company> {
  return axios
    .get(`${process.env.VUE_APP_ACCOUNT_API}/companies/${id}`)
    .then((response) => response.data);
}

export function fetchCompanies(): Promise<Company[]> {
  return axios
    .get(`${process.env.VUE_APP_ACCOUNT_API}/companies`)
    .then((response) => response.data);
}

export function removeCompany(company: Company): Promise<number> {
  return axios
    .delete(`${process.env.VUE_APP_ACCOUNT_API}/companies/${company.id}`)
    .then(() =>
      axios.delete(`${process.env.VUE_APP_ZCLOUD_API}/apikeys/${company.id}`)
    )
    .then((response) => response.status);
}

/* Device */

export function fetchDevices(
  filter: DeviceFilter,
  options: PageOption | undefined
): Promise<Page<Device>> {
  const params = new URLSearchParams();
  let sort = "";

  if (filter.serialNumber) {
    params.append("serial_number", filter.serialNumber);
  }

  if (filter.assetName) {
    params.append("asset_name", filter.assetName);
  }

  if (filter.companyName) {
    params.append("company_name", filter.companyName);
  }

  if (filter.leavingDeepSleep) {
    params.append("leaving_deep_sleep", filter.leavingDeepSleep);
  }

  if (filter.noAssetAssigned) {
    params.append("no_asset_assigned", filter.noAssetAssigned);
  }
  if (options) {
    if (options.limit) {
      params.append("limit", options.limit.toString());
    }

    if (options.page) {
      params.append("page", options.page.toString());
    }

    if (options.limit) {
      params.append("limit", options.limit.toString());
    }

    if (options.page) {
      params.append("page", options.page.toString());
    }

    if (options.sortBy) {
      sort = options.sortBy;
    }

    if (options.sortDesc) {
      sort = `-${sort}`;
    }
  }
  if (sort.length > 0) {
    params.append("sort", sort);
  }

  return axios
    .get(`${process.env.VUE_APP_ZCLOUD_API}/znodes?${params.toString()}`)
    .then((response) => {
      return {
        message: response.data.message,
        data: response.data.content.data,
        pagination: {
          limit: response.data.content.per_page,
          total_items: response.data.content.total,
          current_page: response.data.content.current_page,
        },
      };
    });
}

/* Property */

export function fetchProperties(): Promise<Property[]> {
  return axios
    .get(`${process.env.VUE_APP_ASSET_API}/properties`)
    .then((response) => response.data.data);
}

/* Use case */

export function fetchUseCases(): Promise<UseCase[]> {
  return axios
    .get(`${process.env.VUE_APP_ACCOUNT_API}/useCases`)
    .then((response) => response.data);
}

/* Fence */

export function createFence(
  name: string,
  address: string | null,
  color: string,
  circlePosition: LatLng | null,
  circleRadius: number | null,
  polygonCoords: LatLng[] | null,
  additionalAddress: string[] | null,
  isCovered: boolean | null,
  accessTimes: AccessTime[] | null,
  fenceTypeId: string | null,
  remarks: string | null,
  accessTimeDuration: number | null,
  nfcTagId: string | null,
  qrCodeId: string | null,
  fenceTags: string[] | null,
  fenceKey: string | null,
  fencePhoto: string | null
): Promise<Fence> {
  const data = {
    name,
    address,
    color,
    circle_data:
      circlePosition && circleRadius
        ? {
            position: circlePosition,
            radius: circleRadius,
          }
        : null,
    polygon_data: polygonCoords ?? null,
    additional_address: additionalAddress,
    is_covered: isCovered,
    access_times: accessTimes,
    fence_type_id: fenceTypeId,
    remarks: remarks,
    access_time_duration: accessTimeDuration,
    nfc_tag_id: nfcTagId,
    qr_code_id: qrCodeId,
    fence_tags: fenceTags,
    fence_key: fenceKey,
    fence_photo: fencePhoto,
  };

  return axios
    .post(`${process.env.VUE_APP_ASSET_API}/fences`, data)
    .then((response) => response.data);
}

export function editFence(fence: Fence): Promise<Fence> {
  return axios
    .put(`${process.env.VUE_APP_ASSET_API}/fences/${fence.id}`, fence)
    .then((response) => response.data);
}

export function fetchFenceType(): Promise<FenceType[]> {
  return axios
    .get(`${process.env.VUE_APP_ASSET_API}/fence-types`)
    .then((response) => response.data.data);
}

export function fetchFenceTags(): Promise<FenceTag[]> {
  return axios
    .get(`${process.env.VUE_APP_ASSET_API}/fence-tags`)
    .then((response) => response.data.data);
}

export function fetchFences(): Promise<Fence[]> {
  return axios
    .get(`${process.env.VUE_APP_ASSET_API}/fences`)
    .then((response) => response.data.data);
}

export function fetchFence(id: string): Promise<Fence> {
  return axios
    .get(`${process.env.VUE_APP_ASSET_API}/fences/${id}`)
    .then((response) => response.data.data);
}

export function fetchFenceByName(name: string): Promise<Fence[]> {
  return axios
    .get(`${process.env.VUE_APP_ASSET_API}/fences-name/${name}`)
    .then((response) => response.data);
}

export function fetchFenceAssetLogs(id: string): Promise<FenceAssetLog[]> {
  return axios
    .get(`${process.env.VUE_APP_ASSET_API}/fences/${id}/tracking-logs`)
    .then((response) => response.data.data);
}

export function fetchFenceAssets(id: string): Promise<Asset[]> {
  return axios
    .get(`${process.env.VUE_APP_ASSET_API}/fences/${id}/assets`)
    .then((response) => response.data.data);
}

export function removeFence(id: string): Promise<number> {
  return axios
    .delete(`${process.env.VUE_APP_ASSET_API}/fences/${id}`)
    .then((response) => response.status);
}

/* Asset Volume */
export function fetchAssetVolume(): Promise<AssetVolume[]> {
  return axios
    .get(`${process.env.VUE_APP_ASSET_API}/assets-volume`)
    .then((response) => response.data.data);
}

/* Asset Condition */
export function fetchAssetCondition(): Promise<AssetCondition[]> {
  return axios
    .get(`${process.env.VUE_APP_ASSET_API}/assets-conditions`)
    .then((response) => response.data.data);
}

/* Asset Cleaning */
export function fetchAssetCleaning(): Promise<AssetCleaning[]> {
  return axios
    .get(`${process.env.VUE_APP_ASSET_API}/assets-cleaning`)
    .then((response) => response.data.data);
}

/* Asset Content Material */
export function fetchAssetContentMaterial(): Promise<AssetContentMaterial[]> {
  return axios
    .get(`${process.env.VUE_APP_ASSET_API}/assets-content-material`)
    .then((response) => response.data.data);
}

/* export function fetch Asset Tags */
export function fetchAssetTags(): Promise<AssetTag[]> {
  return axios
    .get(`${process.env.VUE_APP_ASSET_API}/assets-tags`)
    .then((response) => response.data.data);
}

/* Vehicle */

export function fetchVehicles(): Promise<Partial<Vehicle>[]> {
  return axios
    .get(`${process.env.VUE_APP_ASSET_API}/vehicles`)
    .then((response) => response.data.data);
}

export function fetchRMVehicles(): Promise<Partial<Vehicle>[]> {
  return axios
    .get(`${process.env.VUE_APP_RM_API}/vehicles`)
    .then((response) => response.data);
}

export function fetchVehicleById(id: string): Promise<Vehicle> {
  return axios
    .get(`${process.env.VUE_APP_ASSET_API}/vehicles/${id}`)
    .then((response) => response.data.data);
}

export function editVehicle(
  id: string,
  vehicle: Partial<Vehicle>
): Promise<Partial<Vehicle>> {
  return axios
    .put(`${process.env.VUE_APP_ASSET_API}/vehicles/${id}`, vehicle)
    .then((response) => response.data);
}

export function createVehicle(
  vehicle: Partial<Vehicle>
): Promise<Partial<Vehicle>> {
  return axios
    .post(`${process.env.VUE_APP_ASSET_API}/vehicles`, vehicle)
    .then((response) => response.data.data);
}

export function fetchBodyMounts(): Promise<VehiclePart[]> {
  return axios
    .get(`${process.env.VUE_APP_ASSET_API}/vehicle-body-mounts`)
    .then((response) => response.data.data);
}

export function fetchMountManufacturers(): Promise<VehiclePartManufacturer[]> {
  return axios
    .get(`${process.env.VUE_APP_ASSET_API}/vehicle-body-mount-manufacturers`)
    .then((response) => response.data.data);
}

export function fetchLifters(): Promise<VehiclePart[]> {
  return axios
    .get(`${process.env.VUE_APP_ASSET_API}/vehicle-lifters`)
    .then((response) => response.data.data);
}

export function fetchLifterManufacturers(): Promise<VehiclePartManufacturer[]> {
  return axios
    .get(`${process.env.VUE_APP_ASSET_API}/vehicle-lifter-manufacturers`)
    .then((response) => response.data.data);
}

export function fetchLoadCapacities(
  vehicleId: string
): Promise<VehicleLoadCapacity[]> {
  return axios
    .get(
      `${process.env.VUE_APP_ASSET_API}/vehicles/${vehicleId}/load-capacities`
    )
    .then((response) => response.data.data);
}

/**
 * export function edit Load Capacity
 * @param vehicleId
 * @param loadCapacities
 * @example API needs
 * {
 *     "load_capacity": [
 *         {
 *             "content_material_id": "5dfeb3de-5803-44a8-8cde-56b217e9227d",
 *             "volume_id": "779a668f-6966-4778-a46e-10285ad52c58",
 *             "weight_unit_aval_id": "AE.1.103",
 *             "weight": 2049
 *         }
 *     ]
 * }
 */
export function editLoadCapacities(
  vehicleId: string,
  loadCapacities: VehicleLoadCapacity[]
): Promise<void> {
  const copyLoadCapacities = loadCapacities.map((loadCapacity) => {
    const copyLoadCapacity = { ...loadCapacity };
    delete copyLoadCapacity.id;
    delete copyLoadCapacity.created_at;
    delete copyLoadCapacity.updated_at;
    delete copyLoadCapacity.deleted_at;
    return copyLoadCapacity;
  });

  //delete timestamps of load capacities
  return axios
    .put(
      `${process.env.VUE_APP_ASSET_API}/vehicles/${vehicleId}/load-capacities`,
      { load_capacity: copyLoadCapacities }
    )
    .then(() => Promise.resolve());
}

export function fetchChassisManufacturers(): Promise<
  VehiclePartManufacturer[]
> {
  return axios
    .get(`${process.env.VUE_APP_ASSET_API}/vehicle-chassis-manufacturers`)
    .then((response) => response.data.data);
}

export function fetchVehicleModels(): Promise<VehicleModel[]> {
  return axios
    .get(`${process.env.VUE_APP_ASSET_API}/vehicle-models`)
    .then((response) => response.data.data);
}

export function fetchVehicleTypes(): Promise<VehicleType[]> {
  return axios
    .get(`${process.env.VUE_APP_ASSET_API}/vehicle-types`)
    .then((response) => response.data.data);
}

/* Columns */

export function fetchColumns(): Promise<Column[]> {
  //employees returned in column should be in camelCase
  return axios
    .get(`${process.env.VUE_APP_ASSET_API}/columns`)
    .then((response) => camelize(response.data.data) as unknown as Column[]);
}

export function fetchColumnById(id: string): Promise<Column> {
  return axios
    .get(`${process.env.VUE_APP_ASSET_API}/columns/${id}`)
    .then((response) => response.data.data);
}

export function createColumn(column: Partial<Column>): Promise<Column> {
  return axios
    .post(`${process.env.VUE_APP_ASSET_API}/columns`, column)
    .then((response) => response.data.data);
}

export function editColumn(column: Column): Promise<Column> {
  return axios
    .put(`${process.env.VUE_APP_ASSET_API}/columns/${column.id}`, column)
    .then((response) => response.data.data);
}

export function fetchEmployeesByColumnId(
  id: string
): Promise<Partial<UserAccount>[]> {
  return axios
    .get(`${process.env.VUE_APP_ASSET_API}/columns/${id}/employees`)
    .then(
      (response) => camelize(response.data.data) as unknown as UserAccount[]
    );
}

export function updateEmployeesByColumnId(
  columnId: string,
  employeeIds: string[]
): Promise<Partial<UserAccount>[]> {
  return axios
    .put(`${process.env.VUE_APP_ASSET_API}/columns/${columnId}/employees`, {
      user_profile_ids: employeeIds,
    })
    .then(
      (response) => camelize(response.data.data) as unknown as UserAccount[]
    );
}

export function fetchWorkingHoursByColumnId(id: string, from: Date, to: Date) {
  return axios
    .get(
      `${
        process.env.VUE_APP_ASSET_API
      }/columns/${id}/workinghours?from=${from.toISOString()}&to=${to.toISOString()}`
    )
    .then((response) => response.data.data);
}

export function saveWorkingHoursForColumnId(
  id: string,
  workingHours: WorkingHour[]
): Promise<void> {
  //expand working hours times to string with seconds (00:00:00)
  const workingHoursWithSeconds = workingHours.map((workingHour) => {
    const copyWorkingHour = { ...workingHour };
    copyWorkingHour.pause_end += ":00";
    copyWorkingHour.pause_start += ":00";
    copyWorkingHour.shift_start += ":00";
    copyWorkingHour.shift_end += ":00";
    return copyWorkingHour;
  });
  return axios
    .put(`${process.env.VUE_APP_ASSET_API}/columns/${id}/workinghours`, {
      working_hours: workingHoursWithSeconds,
    })
    .then(() => Promise.resolve());
}

/* Recycling Manager */

export function fetchDepartments(): Promise<Department[]> {
  return axios
    .get(`${process.env.VUE_APP_ASSET_API}/departments`)
    .then((response) => response.data.data);
}

export function createDepartment(
  department: Partial<Department>
): Promise<Department> {
  return axios
    .post(`${process.env.VUE_APP_ASSET_API}/departments`, department)
    .then((response) => response.data.data);
}

export function editDepartment(
  department: Partial<Department>
): Promise<Department> {
  //copy object and export function remove id
  const departmentCopy = { ...department } as Partial<Department>;
  delete departmentCopy.id;

  return axios
    .put(
      `${process.env.VUE_APP_ASSET_API}/departments/${department.id}`,
      departmentCopy
    )
    .then((response) => response.data.data);
}

export function deleteDepartment(department: Department): Promise<Department> {
  return axios
    .delete(`${process.env.VUE_APP_ASSET_API}/departments/${department.id}`)
    .then((response) => response.data.data);
}

export function fetchDuties(): Promise<Duty[]> {
  return axios
    .get(`${process.env.VUE_APP_ASSET_API}/duties`)
    .then((response) => response.data.data);
}

export function createDuty(duty: Duty): Promise<Duty> {
  return axios
    .post(`${process.env.VUE_APP_ASSET_API}/duties`, duty)
    .then((response) => response.data.data);
}

export function editDuty(duty: Duty): Promise<Duty> {
  //copy object and export function remove id
  const dutyCopy = { ...duty } as Partial<Duty>;
  delete dutyCopy.id;

  return axios
    .put(`${process.env.VUE_APP_ASSET_API}/duties/${duty.id}`, dutyCopy)
    .then((response) => response.data.data);
}

export function deleteDuty(duty: Duty): Promise<string> {
  return axios
    .delete(`${process.env.VUE_APP_ASSET_API}/duties/${duty.id}`)
    .then((response) => response.data.data);
}

export function fetchQRScanInfo(qrCodeId: string): Promise<QRScanInfo> {
  return axios
    .get(`${process.env.VUE_APP_REPORTING_API}/asset?qrCodeId=${qrCodeId}`)
    .then((response) => response.data);
}

export function fetchIncidents(): Promise<ReportIncident[]> {
  return axios
    .get(`${process.env.VUE_APP_REPORTING_API}/report/filling-level/incidents`)
    .then((response) => response.data);
}

export function fetchNfcScanLogs(
  filter?: ReportFilter
): Promise<SpringPage<NfcScanLog>> {
  return axios
    .get(
      `${
        process.env.VUE_APP_REPORTING_API
      }/scans/nfc-logs${filter?.toQueryString()}`
    )
    .then((response) => response.data);
}

export function editReportStatus(
  reportId: string,
  status: number
): Promise<Report> {
  return axios
    .put(
      `${process.env.VUE_APP_REPORTING_API}/report/${reportId}/status`,
      status.toString(),
      { headers: { "Content-Type": "application/json" } }
    )
    .then((response) => response.data);
}

export function deleteReport(reportId: string): Promise<Report> {
  return axios
    .delete(`${process.env.VUE_APP_REPORTING_API}/report/${reportId}`)
    .then((response) => response.data);
}

export function fetchQRScanLogs(
  filter?: ReportFilter
): Promise<SpringPage<QRScanLog>> {
  return axios
    .get(
      `${
        process.env.VUE_APP_REPORTING_API
      }/scans/qr-logs${filter?.toQueryString()}`
    )
    .then((response) => response.data);
}

export function sendCitizenReport(
  reportDTO: ReportDTO,
  qrScanLogId: string
): Promise<Report> {
  const data = new FormData();
  const report = new Blob([JSON.stringify(reportDTO.report)], {
    type: "application/json",
  });
  data.append("report", report);
  if (reportDTO.photos) {
    for (let i = 0; i < reportDTO.photos.length; i++) {
      data.append("photos", reportDTO.photos?.[i]);
    }
  }

  return axios
    .post(
      `${process.env.VUE_APP_REPORTING_API}/report?qrScanLogId=${qrScanLogId}`,
      data
    )
    .then((response) => response.data);
}

export function fetchReportsByAssetId(
  assetId: string,
  filter?: ReportFilter
): Promise<SpringPage<QRScanLog | NfcScanLog>> {
  return axios
    .get(
      `${
        process.env.VUE_APP_REPORTING_API
      }/asset/${assetId}/reports${filter?.toQueryString()}`
    )
    .then((response) => {
      const combinedPage = response.data as SpringPage<CombinedScanLog>;
      const content = combinedPage.content.map((item) => {
        if (item.qrScanLog) {
          return item.qrScanLog;
        } else if (item.nfcScanLog) {
          return item.nfcScanLog;
        } else {
          return {};
        }
      }) as (QRScanLog | NfcScanLog)[];
      return {
        ...combinedPage,
        content: content,
      };
    });
}

/**
 * Fetches all materials which are assigned to a container at this location
 * Per container on this location, one material is returned -
 * e.g. the number of materials returned resembles the number of containers on this location
 * @param locationId
 */
export function fetchLocationMaterials(
  locationId: string
): Promise<SpringPage<LocationContainerContent>> {
  return axios
    .get(
      `${process.env.VUE_APP_RM_API}/locations/${locationId}/content-material-log`
    )
    .then((response) => response.data);
}

export function fetchLocationById(id: string): Promise<Location> {
  return axios
    .get(`${process.env.VUE_APP_RM_API}/locations/${id}`)
    .then((response) => response.data);
}

export function fetchLocations(
  filter: Filter<LocationFilter>
): Promise<SpringPage<Location>> {
  return axios
    .get(`${process.env.VUE_APP_RM_API}/locations?${filter.toQueryString()}`)
    .then((response) => response.data);
}

/**
 * @deprecated Will be replaced by "fetchLocations", using a general filter
 * @param filter
 */
export function fetchLocationsByBbox(
  filter: Filter<LocationFilter>
): Promise<Location[]> {
  return axios
    .get(
      `${process.env.VUE_APP_RM_API}/locations/box?${filter.toQueryString()}`
    )
    .then((response) => response.data);
}

export function deleteLocation(locationId: string): Promise<string> {
  return axios
    .delete(`${process.env.VUE_APP_RM_API}/locations/${locationId}`)
    .then((response) => response.data);
}

export function updateLocation(location: Location): Promise<Location> {
  return axios
    .patch(`${process.env.VUE_APP_RM_API}/locations/${location.id}`, location)
    .then((response) => response.data);
}

export function fetchAssetsAtLocation(locationId: string): Promise<Asset[]> {
  return axios
    .get(
      `${process.env.VUE_APP_RM_API}/locations/${locationId}/containers`
    )
    .then((response) => response.data);
}

export function fetchLocationContactPersons(
  locationId: string
): Promise<ContactPerson[]> {
  return axios
    .get(
      `${process.env.VUE_APP_RM_API}/locations/${locationId}/contact-persons`
    )
    .then((response) => response.data);
}

export function fetchLocationContainers(
  locationId: string
): Promise<LocationContainer[]> {
  return axios
    .get(`${process.env.VUE_APP_ASSET_API}/fences/${locationId}/assets`)
    .then((response) => {
      return response.data.data;
    });
}

export function saveLocation(location: Location): Promise<Location> {
  return axios
    .post(`${process.env.VUE_APP_RM_API}/locations`, location)
    .then((response) => response.data);
}

export function saveContactPerson(
  person: ContactPerson,
  locationId: string
): Promise<ContactPerson> {
  return axios
    .post(
      `${process.env.VUE_APP_RM_API}/locations/${locationId}/contact-persons`,
      person
    )
    .then((response) => response.data);
}

export function createMultipleJobs(
  multipleJobRequest: CreateMultipleJobsRequest
): Promise<SpringPage<Job>> {
  return axios
    .post(
      `${process.env.VUE_APP_RM_API}/jobs/create-multiple-jobs`,
      multipleJobRequest
    )
    .then((response) => response.data);
}

export function fetchJobTasks(): Promise<JobTask[]> {
  return axios
    .get(`${process.env.VUE_APP_RM_API}/tasks`)
    .then((response) => response.data);
}

export function fetchJobsByAsset(assetId: string, filter: Filter<JobFilter>): Promise<Job[]> {
  return axios
    .get(`${process.env.VUE_APP_RM_API}/containers/${assetId}/jobs?${filter.toQueryString()}`)
    .then((response) => response.data);
}

export function fetchJobsByGeoReport(reportId: string, filter: Filter<JobFilter>): Promise<Job[]> {
  return axios
    .get(`${process.env.VUE_APP_RM_API}/reporting/geo-reports/${reportId}/jobs?${filter.toQueryString()}`)
    .then((response) => response.data);
}

export function fetchJobs(filter: Filter<JobFilter>): Promise<SpringPage<Job>> {
  return axios
    .get(`${process.env.VUE_APP_RM_API}/jobs?${filter.toQueryString()}`)
    .then((response) => response.data);
}

export function fetchJobsByBBox(filter: Filter<JobFilter>): Promise<Job[]> {
  return axios
    .get(`${process.env.VUE_APP_RM_API}/jobs/box?${filter.toQueryString()}`)
    .then((response) => response.data);
}

export function fetchAggregatedJobs(filter: JobFilter): Promise<JobsBySubject[]> {
  return axios
    .get(`${process.env.VUE_APP_RM_API}/jobs/aggregated-by-subject?${filter.toQueryString()}`)
    .then((response) => response.data);
}

export function fetchAvalCatalogs(): Promise<AvalCatalog[]> {
  return axios
    .get(`${process.env.VUE_APP_AVAL_API}/catalog`)
    .then((response) => response.data);
}

export function fetchAvalUnits(): Promise<AvalCatalogEntry[]> {
  return axios
    .get(`${process.env.VUE_APP_AVAL_API}/catalog/AE.1/structure`)
    .then((response) => response.data);
}

export function fetchAvalServices(): Promise<AvalCatalogEntry[]> {
  return axios
    .get(`${process.env.VUE_APP_AVAL_API}/catalog/AL.1/structure`)
    .then((response) => response.data);
}

export function fetchAvalMaterials(): Promise<AvalCatalogEntry[]> {
  return axios
    .get(`${process.env.VUE_APP_AVAL_API}/catalog/AS.1/structure`)
    .then((response) => response.data);
}

export function fetchAvalContainerTypes(): Promise<AvalCatalogEntry[]> {
  return axios
    .get(`${process.env.VUE_APP_AVAL_API}/catalog/AB.1/structure`)
    .then((response) => response.data);
}

export function fetchAvalCycles(): Promise<AvalCatalogEntry[]> {
  return axios
    .get(`${process.env.VUE_APP_AVAL_API}/catalog/AZ.1/structure`)
    .then((response) => response.data);
}

export function fetchAvalIncidents(): Promise<AvalCatalogEntry[]> {
  return axios
    .get(`${process.env.VUE_APP_AVAL_API}/catalog/AV.1/structure`)
    .then((response) => response.data);
}

export function fetchAvalDocumentTypes(): Promise<AvalCatalogEntry[]> {
  return axios
    .get(`${process.env.VUE_APP_AVAL_API}/catalog/AD.1/structure`)
    .then((response) => response.data);
}

/**
 * Sends multiple DELETE requests to the given api and path
 * @param api e.g. http://localhost:8080 / 'process.env.VUE_APP_RM_API'
 * @param path e.g. '/locations'
 * @param items items to delete
 */
export function deleteMultiple<T extends HasId>(
  api: string,
  path: string,
  items: T[]
): Promise<string[] | string> {
  let ids: string[];
  if (items) {
    ids = items.map((item) => item.id as string);

    //send DELETE for every id
    //await all promises before returning
    return Promise.all(
      ids.map((id) => axios.delete(`${api}/${path}/${id}`))
    ).then((response) => response.map((res) => res.data));
  } else {
    throw new Error("No items to delete");
  }
}
