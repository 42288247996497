import { Status } from "@/types/general/general";

export const vehicleStatuses = [
  {
    id: 0,
    name: "Betriebsbereit",
  },
  {
    id: 1,
    name: "Wartung",
  },
  {
    id: 2,
    name: "Reperaturbedürftig",
  },
  {
    id: 3,
    name: "Im Einsatz",
  },
] as Status[];
export const reportStatuses = [
  {
    id: 0,
    name: "Offen",
    colorClass: "status-color-1",
  },
  {
    id: 1,
    name: "In Bearbeitung",
    colorClass: "status-color-2",
  },
  {
    id: 2,
    name: "Erledigt",
    colorClass: "status-color-3",
  },
] as Status[];

export enum JobStatuses {
  OPEN = 0,
  IN_PROGRESS = 1,
  DONE = 2,
}
