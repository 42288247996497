import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { CitizenReports, RootState } from "@/store/types";
import {
  deleteReport,
  editReportStatus,
  fetchIncidents,
  fetchQRScanInfo,
  sendCitizenReport,
} from "@/api/server";
import { QRScanInfo, Report, ReportDTO, ReportIncident } from "@/types/report";

const state: CitizenReports = {
  incidents: [],
};

const getters: GetterTree<CitizenReports, RootState> = {};

const actions: ActionTree<CitizenReports, RootState> = {
  fetchQRScanInfo(_, qrCodeId: string) {
    return fetchQRScanInfo(qrCodeId).then((qrScanInfo: QRScanInfo) => {
      return qrScanInfo;
    });
  },
  fetchIncidents({ commit }) {
    fetchIncidents().then((incidents: ReportIncident[]) => {
      //Filter out incidents that are not relevant for the citizen
      //For now this is "household_waste"
      incidents = incidents.filter((incident: ReportIncident) => {
        return incident.name !== "household_waste";
      });
      commit("setIncidents", incidents);
    });
  },
  editReport(_, { id, status }: { id: string; status: number }) {
    return editReportStatus(id, status);
  },
  deleteReport(_, id: string) {
    return deleteReport(id);
  },
  sendReport(
    _,
    { qrScanLogId, reportData }: { qrScanLogId: string; reportData: ReportDTO }
  ) {
    sendCitizenReport(reportData, qrScanLogId).then((response: Report) => {
      return response;
    });
  },
};

const mutations: MutationTree<CitizenReports> = {
  setIncidents(state, incidents: ReportIncident[]) {
    state.incidents = incidents;
  },
};

const reports: Module<CitizenReports, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default reports;
