
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "DarkModeToggle",
  setup() {
    const darkMode = ref(false);
    return { darkMode };
  },
  created() {
    //load dark mode settings from session
    const darkMode = this.$session.get("darkMode") || false;
    this.darkMode = darkMode;
    this.$vuetify.theme.dark = darkMode;
  },
  methods: {
    toggleDarkMode(setting: boolean) {
      //save dark mode settings to session
      this.$session.set("darkMode", setting);
      //set dark mode
      this.$vuetify.theme.dark = setting;
    },
  },
});
