import { isEmpty } from "lodash";
import {
  Ability,
  AbilityBuilder,
  AbilityClass,
  MongoQuery,
  SubjectRawRule,
} from "@casl/ability";
import { Permission } from "@/types/user-management/access-policy";
import { UserAccount } from "@/types/user-management/user-account";

//Standard CRUD-based actions a user can do. "manage" is reserved by CASL and means "all" actions.
//Transforms Group enum -> pipe separated strings = type def. "" | ""
export type Actions = (typeof Permission)[keyof typeof Permission] | "manage";
//TODO: define types for CASL subjects. This can be route-paths or subjects as "asset" from route meta data
//The domain the access-right is requested for. <br>
//E.g. "asset" results in the ability; $can('create', 'asset')
export type Subjects = string;

export type AppAbility = Ability<[Actions, Subjects]>;
export const AppAbility = Ability as AbilityClass<AppAbility>;

/**
 * Initializes CASL based on the user's AccessPolicy (Roles, Routes, Groups).
 * Creates abilities available for access-checks, such as $can('create', 'asset').
 * **/
export function defineRulesFor(
  user: UserAccount
): SubjectRawRule<
  Actions,
  string,
  MongoQuery<Record<string | number | symbol, unknown>>
>[] {
  const { can, rules } = new AbilityBuilder(AppAbility);
  if (isEmpty(user.roles)) return [];

  const userModules = user.roles.map((role) => role.modules).flat();

  // If user has admin role or is "Dashboard admin", grant access to everything
  if (user.AccessLevel.id === "00000000-0000-0000-0000-000000000011") {
    //"manage" is reserved by CASL and means "all" Actions
    can("manage", "all");
    return rules;
  }

  // Iterate over module's permissions and create abilities
  userModules.forEach((module) => {
    for (const permission in module.permissions) {
      //If the permission is true, grant access to the routes inside the module
      if (module.permissions[permission as Permission]) {
        //Save
        can(permission as Actions, module.name);
        //The permission equals the action, e.g. "can_read" - The route is the subject the rule is saved for.
        module.routes.forEach((route) => {
          // if the route path contains more than one slash, it is a nested route e.g. /reports/app
          // to allow the top level, e.g. /reports/ in /reports/app, strip the last part of the path after the first slash
          const firstRoutePart = route.route.split("/")[1];
          can(permission as Actions, `/${firstRoutePart}`);
          can(permission as Actions, route.route);
        });
      }
    }
  });

  return rules;
}
