import Vue from "vue";
import Vuetify, { VBtn, VIcon, VSnackbar } from "vuetify/lib";
import VuetifyToast from "vuetify-toast-snackbar";
import de from "vuetify/src/locale/de";

const vuetifyObj = new Vuetify({
  lang: {
    locales: { de },
    current: "de",
  },
});

Vue.use(Vuetify, {
  components: {
    VBtn,
    VIcon,
    VSnackbar,
  },
});

Vue.use(VuetifyToast, {
  $vuetify: vuetifyObj.framework,
  x: "center",
  timeout: 5000,
  queueable: true,
});

export default vuetifyObj;
