
import Vue from "vue";
import Component from "vue-class-component";
import { mapState } from "vuex";

@Component({
  components: { CompanyLogo },
  computed: {
    ...mapState("company", {
      companyLogo: "logo",
      companyName: "name",
      companyId: "id",
    }),
  },
})
export default class CompanyLogo extends Vue {
  companyLogo?: string;
  companyName?: string;
  companyId?: string;

  get companyLogoUri() {
    //if img has mimetype in base64, return it
    if (this.companyLogo && this.companyLogo.includes("data:image")) {
      return this.companyLogo;
    } else {
      //if no mimetype is set, add this default
      return "data:image/jpeg;base64" + this.companyLogo;
    }
  }
}
