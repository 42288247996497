import AssetCleaning from "@/types/asset/asset-cleaning";
import AssetCondition from "@/types/asset/asset-condition";
import AssetContentMaterial from "@/types/asset/asset-content-material";
import AssetType from "@/types/asset/asset-type";
import AssetTypeLight from "@/types/asset/asset-type-light";
import AssetVolume from "@/types/asset/asset-volume";
import Device, { DeviceFilter } from "@/types/device";
import Page, { PageOption } from "@/types/page";
import Property from "@/types/asset/property";

import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { AssetProperties } from "@/store/types";
import { RootState } from "../types";

import { isEmpty } from "lodash";
import { AssetTag } from "@/types/general/general";
import {
  fetchAssetCleaning,
  fetchAssetCondition,
  fetchAssetContentMaterial,
  fetchAssetTags,
  fetchAssetTypes,
  fetchAssetTypesFlat,
  fetchAssetVolume,
  fetchDevices,
  fetchProperties,
} from "@/api/server";

const state: AssetProperties = {
  cleanings: [] as AssetCleaning[],
  conditions: [] as AssetCondition[],
  contentMaterials: [] as AssetContentMaterial[],
  devices: {} as Page<Device>,
  properties: [] as Property[],
  tags: [] as AssetTag[],
  types: [] as AssetType[],
  typesFlat: [] as AssetTypeLight[],
  volumes: [] as AssetVolume[],
};

const getters: GetterTree<AssetProperties, RootState> = {
  // Array of available ZNodes without Pagination Object
  deviceList: (state) => state.devices.data,
  deviceListLength: (state) => {
    if (state.devices.pagination && state.devices.pagination.total_items) {
      return state.devices.pagination.total_items;
    } else if (state.devices.data) {
      return state.devices.data.length;
    }
    return 0;
  },
  material: (state) => (id: string) => {
    return state.contentMaterials.find((material) => material.id === id);
  },
  materialName: (state) => (id: string) => {
    const mat = state.contentMaterials.find(
      (material) => material.id === id
    ) as AssetContentMaterial | undefined;
    return mat && mat.id ? mat.name : undefined;
  },
  volume: (state) => (id: string) => {
    return state.volumes.find((material) => material.id === id);
  },
  volumeName: (state) => (id: string) => {
    const vol = state.volumes.find((material) => material.id === id) as
      | AssetVolume
      | undefined;
    return vol && vol.id ? vol.description : undefined;
  },
  tagsAsHasNameArray: (state) => {
    return state.tags.map((tag) => {
      return { name: tag.text };
    });
  },
  typeById:
    (state) =>
    (id: string): AssetType | undefined => {
      // if AssetType has children and no type id matches, search for matching type id's in children
      return findMatchingAssetType(state.types, id);
    },
  typeIconById:
    (state) =>
    (id: string): string | undefined => {
      const type = findMatchingAssetType(state.types, id);
      if (!type) return undefined;

      return type.icon_b64 || undefined;
    },
  flatTypeById:
    (state) =>
    (id: string): AssetTypeLight | undefined => {
      return state.typesFlat.find((type) => type.id === id);
    },
};

function findMatchingAssetType(
  assetTypes: AssetType[],
  matchingId: string
): AssetType | undefined {
  for (const assetType of assetTypes) {
    if (assetType.id === matchingId) {
      return assetType;
    }

    if (assetType.children) {
      const found = findMatchingAssetType(assetType.children, matchingId);
      if (found) {
        return found;
      }
    }
  }

  return undefined;
}

const actions: ActionTree<AssetProperties, RootState> = {
  fetchCleanings({ state, commit }) {
    if (state.cleanings && state.cleanings.length > 0) return;
    return fetchAssetCleaning().then((assetCleaning: AssetCleaning[]) =>
      commit("setCleanings", assetCleaning)
    );
  },
  fetchConditions({ state, commit }) {
    if (state.conditions && state.conditions.length > 0) return;
    return fetchAssetCondition().then((assetCondition: AssetCondition[]) =>
      commit("setConditions", assetCondition)
    );
  },
  fetchDevices(
    { state, commit },
    {
      filter,
      options,
      force = false,
    }: { filter: DeviceFilter; options: PageOption; force: boolean }
  ) {
    if (!force && !isEmpty(state.devices)) return;

    if (!filter) filter = {} as DeviceFilter;
    if (!options) options = {} as PageOption;
    return fetchDevices(filter, options).then((devices: Page<Device>) =>
      commit("setDevices", devices)
    );
  },
  fetchTags({ state, commit }) {
    if (state.tags && state.tags.length > 0) return;
    return fetchAssetTags().then((tags: AssetTag[]) => commit("setTags", tags));
  },
  fetchContentMaterials({ state, commit }) {
    if (state.contentMaterials && state.contentMaterials.length > 0) return;
    return fetchAssetContentMaterial().then(
      (contentMaterials: AssetContentMaterial[]) =>
        commit("setContentMaterials", contentMaterials)
    );
  },
  fetchProperties({ state, commit }) {
    if (state.properties && state.properties.length > 0) return;
    return fetchProperties().then((properties: Property[]) =>
      commit("setProperties", properties)
    );
  },
  fetchTypes({ commit, state }) {
    if (state.types && state.types.length > 0) return;
    return fetchAssetTypes().then((types: AssetType[]) =>
      commit("setTypes", types)
    );
  },
  fetchFlatTypes({ state, commit }) {
    if (state.typesFlat && state.typesFlat.length > 0) return;
    return fetchAssetTypesFlat().then((flatTypes: AssetTypeLight[]) =>
      commit("setFlatTypes", flatTypes)
    );
  },
  fetchVolumes({ state, commit }) {
    if (state.volumes && state.volumes.length > 0) return;
    return fetchAssetVolume().then((volumes: AssetVolume[]) =>
      commit("setVolumes", volumes)
    );
  },
};

const mutations: MutationTree<AssetProperties> = {
  setState(state, assetProperties: AssetProperties) {
    Object.assign(state, assetProperties);
  },
  setCleanings(state, cleanings: AssetCleaning[]) {
    state.cleanings = cleanings;
  },
  setConditions(state, conditions: AssetCondition[]) {
    state.conditions = conditions;
  },
  setContentMaterials(state, contentMaterials: AssetContentMaterial[]) {
    state.contentMaterials = contentMaterials;
  },
  setProperties(state, properties: Property[]) {
    state.properties = properties;
  },
  setTypes(state, types: AssetType[]) {
    state.types = types;
  },
  setTags(state, tags: AssetTag[]) {
    state.tags = tags;
  },
  setFlatTypes(state, types: AssetTypeLight[]) {
    state.typesFlat = types;
  },
  setDevices(state, devices: Page<Device>) {
    state.devices = devices;
  },
  setVolumes(state, volumes: AssetVolume[]) {
    state.volumes = volumes;
  },
};

const assetProperties: Module<AssetProperties, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
export default assetProperties;
