import VueRouter from "vue-router";
import Vue from "vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/buerger/:qrCodeId",
    name: "CitizenParticipationView",
    component: () => import("@/views/misc/CitizenParticipationForm.vue"),
    meta: {
      noAuth: true,
      title: "Bürgerbeteiligung",
      showNav: false,
    },
    props: true,
  },
];

/**
 * Router for the citizen participation app.
 * Separated from main router to exclude ZCloud frontend pages.
 */
const citizenRouter = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default citizenRouter;
