import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "@/store/types";
import { UserAccount } from "@/types/user-management/user-account";
import { Role } from "@/types/user-management/access-policy";
import AccessLevel from "@/types/access-level";
import Company from "@/types/company";
import AuthResult from "@/types/user-management/auth-result";
import Vue from "vue";
import { Department } from "@/types/recycling-manager/department";
import { Duty } from "@/types/recycling-manager/duty";
import { showToastWarning } from "@/plugins/utilities";
import { setUserTokens } from "@/plugins/axios";
import { editAccount, fetchPersonalAccount, login, logout } from "@/api/server";

/**
 * User account in current session.
 */
const initialState: UserAccount = {
  id: "",
  AccessLevel: {} as AccessLevel,
  Company: {} as Partial<Company>,
  departments: [] as Department[],
  roles: [] as Role[],
  duties: [] as Duty[],
  firstName: "",
  lastName: "",
  mail: "",
  phone: "",
};

const state: UserAccount = {
  ...initialState,
};

const getters: GetterTree<UserAccount, RootState> = {
  user: (state) => state,
  accessLevelNr: (state) => {
    switch (state.AccessLevel.id) {
      case "00000000-0000-0000-0000-000000000001": //User
        return 2;
      case "00000000-0000-0000-0000-000000000010": //Company admin
        return 1;
      case "00000000-0000-0000-0000-000000000011": //Dashboard admin - highest
        return 0;
    }
  },
  //get user company
  company: (state) => state.Company,
};

const actions: ActionTree<UserAccount, RootState> = {
  //currently this is done in LoginView
  async login(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _,
    { mail, password }: { mail: string; password: string }
  ): Promise<AuthResult> {
    return login(mail, password).then((authResult) => {
      setUserTokens(authResult);
      return authResult;
    });
  },
  async logout({ commit, state }): Promise<void> {
    return logout(Vue.prototype.$session.get("refreshToken"))
      .then(() => {
        Vue.prototype.$session.destroy();

        //only use gtag in production
        if (process.env.NODE_ENV !== "development") {
          Vue.prototype.$gtag.event("logout", {
            method: "manual",
            user_id: state.id,
          });
        }

        commit("resetUser");
      })
      .catch(() => {
        showToastWarning("Logout fehlgeschlagen");
      });
  },
  fetchUser({ commit }): Promise<UserAccount> {
    return fetchPersonalAccount().then((user) => {
      commit("setUser", user);
      return user;
    });
  },
  /**
   * Used to edit the current user.
   * Other account CRUD functions are within the store {@link userManagement:editAccount}
   */
  editUser(
    { state, commit },
    changedData: Partial<UserAccount>
  ): Promise<void> {
    return editAccount(state.id, changedData).then(() => {
      Vue.prototype.$session.set("companyID", changedData.Company?.id);
      commit("setUser", changedData);
    });
  },
};

const mutations: MutationTree<UserAccount> = {
  setUser(state, account: UserAccount) {
    Object.assign(state, account);
  },
  resetUser(state) {
    Object.assign(state, initialState);
  },
};

const user: Module<UserAccount, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
export default user;
