import { HasName } from "@/types/vue/mixins";

/**
 * A (user's) role containing a RouteCollection with permissions on modules assigned to this role.
 * **/
export interface Role extends HasName {
  id: string;
  name: string;
  modules: RbacModule[];
}

export type RbacModule = RbacUserModule | RbacRouteModule;
interface RbacBasicModule extends HasName {
  id: string;
  name: string;
}
//When fetching a user, the routes are included into the module alongside permissions
export interface RbacUserModule extends RbacBasicModule {
  routes: Route[];
  permissions: PermissionCollection;
}
//When fetching a module, the permissions are not included
export interface RbacRouteModule extends RbacBasicModule {
  routes: Route[];
  permissions?: never;
}

export type PermissionCollection = {
  [value in Permission]?: boolean;
};

export enum Permission {
  create = "can_create",
  read = "can_read",
  update = "can_update",
  delete = "can_delete",
}

export type Route = {
  id: string;
  name: string;
  route: string; //path
  appname: string; //name of the app the route belongs to
};
