
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import {
  QRScanInfo,
  QRScanLog,
  QRScanLogAsset,
  ReportDTO,
  ReportIncident,
} from "@/types/report";
import { mapState } from "vuex";
import ImgUploadSingle from "@/components/media/ImgUploadLocal.vue";
import CompanyLogo from "@/components/media/CompanyLogo.vue";
import { AxiosError } from "axios";

/**
 * Main Page for the citizen participation app.
 * Includes the Form for the actual citizen report and the following confirmation "screen".
 */
@Component({
  components: { CompanyLogo, ImgUploadSingle },
  computed: {
    ...mapState("reports", ["incidents"]),
  },
})
export default class CitizenParticipationForm extends Vue {
  @Prop()
  qrCodeId: string | undefined;

  incidents!: ReportIncident[];
  formValid = false;

  readonly rules = {
    textArea: [(v: string) => v.length <= 255 || "Max. 255 Zeichen"],
    incidents: [
      (v: number[]) =>
        v.length > 0 || "Bitte wählen Sie mindestens einen Grund aus",
    ],
  };

  loading = true;
  submitSuccess = false;
  hasError = false;
  errorMessage = "";

  citizenReportDTO = {
    report: {
      incidentIds: [],
      comment: "",
    },
    photos: [],
  } as ReportDTO;
  qrScanLogAsset = {} as QRScanLogAsset;
  qrScanLog = {} as QRScanLog;

  async mounted() {
    document.title = "Bürgerbeteiligung";

    this.loading = true;
    try {
      await this.$store.dispatch("reports/fetchIncidents");
      const qrScanInfo: QRScanInfo = await this.$store.dispatch(
        "reports/fetchQRScanInfo",
        this.qrCodeId
      );
      this.qrScanLogAsset = qrScanInfo.qrScanLog.asset;
      this.qrScanLog = qrScanInfo.qrScanLog;
    } catch (err) {
      this.hasError = true;
      if (err instanceof AxiosError) {
        if (err.response?.status === 404) {
          this.errorMessage = `Es konnte kein Container für den QR-Code ${this.qrCodeId} gefunden werden.`;
        } else {
          this.errorMessage = `Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut: \n Error: ${err.message}`;
        }
      }
    }
    this.loading = false;
  }

  submit() {
    if (
      this.citizenReportDTO.report.comment &&
      this.citizenReportDTO.report.comment.length < 1
    ) {
      this.citizenReportDTO.report.comment = null;
    }

    this.$store
      .dispatch("reports/sendReport", {
        qrScanLogId: this.qrScanLog.id,
        reportData: this.citizenReportDTO,
      })
      .then(() => {
        this.submitSuccess = true;
      });
  }

  //TODO: watch correct variable and implement endpoint that works without authorization
  @Watch("qrScanAsset")
  onQrScanLogChange(qrScanAsset: QRScanLogAsset) {
    //fetch the company from the asset
    if (qrScanAsset.companyId) {
      this.$store.dispatch("company/fetchById", qrScanAsset.companyId);
    }
  }
}
