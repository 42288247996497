
import { Prop, Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import { Dashboard } from "@uppy/vue";
import Uppy, { UploadResult } from "@uppy/core";
import Compressor from "@uppy/compressor";
import German from "@uppy/locales/lib/de_DE.js";

import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import "@uppy/webcam/dist/style.css";
import "@uppy/image-editor/dist/style.css";

@Component({
  components: {
    Dashboard,
  },
})
export default class ImgUploadSingle extends Vue {
  //List of already loaded image (base64)
  @Prop({ default: undefined })
  value!: string;
  @Prop({ default: false })
  returnBlob!: boolean;
  @Prop({ default: 1 })
  maxFiles!: number;
  @Prop({ default: "285px" })
  height!: string;

  @Prop({ default: "Dateien hier ablegen/einfügen oder %{browseFiles}" })
  title!: string;
  @Prop({ default: "Bild auswählen" })
  optionTitle!: string;

  //Uppy instance
  uppy = new Uppy({
    restrictions: {
      allowedFileTypes: ["image/*"],
      maxNumberOfFiles: this.maxFiles,
    },
    locale: German,
    autoProceed: false, //set to true after init
  })
    .use(Compressor, {
      //https://github.com/fengyuanchen/compressorjs#options can also be passed
      minWidth: 250,
      minHeight: 250,
      maxWidth: 500,
      maxHeight: 500,
      //https://uppy.io/docs/compressor/#options
      quality: 0.8,
    } as never) //mute type error
    .on("complete", (result) => {
      // on "complete" files have been pre-processed and uploaded
      // result.successful contains the files that were successfully uploaded
      // in this case we are not really uploading and just want to get the base64
      if (result.successful.length > 0) {
        //process the uploads in component scope
        this.processUploads(result);
      }
    })
    .on("file-removed", (file, reason) => {
      if (reason === "removed-by-user") this.$emit("input", null);
    });

  mounted() {
    // wait for logo to be loaded
    this.$nextTick(() => {
      // set existing images
      if (this.value && !this.returnBlob) {
        this.uppy.addFile({
          type: "image/jpeg",
          data: this.dataURItoBlob(this.value),
        });
      }
    });
    this.uppy.setOptions({ autoProceed: true });
  }

  processUploads(result: UploadResult) {
    if (this.returnBlob) {
      //emit an array of blobs from the results
      const blobArray: Blob[] = [];
      result.successful.forEach((file) => {
        blobArray.push(file.data);
      });
      this.$emit("input", blobArray);
    } else {
      // if maxFiles is 1, just emit the base64
      // this is an exception as the company edit dialog only supports one logo
      if (this.maxFiles === 1) {
        this.blobToBase64(result.successful[0].data).then((base64) => {
          this.$emit("input", base64);
        });
      } else {
        // if maxFiles is > 1, emit an array of base64
        const base64Array: string[] = [];
        result.successful.forEach((file) => {
          this.blobToBase64(file.data).then((base64) => {
            // cast to string because of type error
            base64Array.push(base64 as string);
            if (base64Array.length === result.successful.length) {
              this.$emit("input", base64Array);
            }
          });
        });
      }
    }
  }

  //base64 to blob
  dataURItoBlob(dataURI: string) {
    // if mimetype is present, extract it. otherwise use default
    const uriParts = dataURI.split(",");
    let byteString: string;
    let mimeString: string;
    if (uriParts.length <= 1) {
      byteString = atob(dataURI);
      mimeString = "image/jpeg";
    } else {
      byteString = atob(uriParts[1]);
      mimeString = uriParts[0].split(":")[1].split(";")[0];
    }

    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }

  //returns base64 string of blob
  blobToBase64(blob: Blob): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  beforeDestroy() {
    this.uppy.close({ reason: "unmount" });
  }
}
