import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "../types";
import Asset, { AssetProperty } from "@/types/asset/asset";
import router from "@/router";
import moment from "moment";
import { AssetTag } from "@/types/general/general";
import { showToastError } from "@/plugins/utilities";
import { createAsset, editAsset, fetchAsset } from "@/api/server";

const initialState: Asset = {
  photos: [],
  alternative_queue_version_id: "",
  asset_barcode: "",
  asset_cleaning_id: "",
  asset_cleaning_name: "",
  asset_condition_id: "",
  asset_condition_name: "",
  asset_content_material_id: "",
  asset_content_material_name: "",
  asset_nfccode: "",
  asset_qrcode: "",
  asset_rfidcode: "",
  asset_sn: "",
  asset_tags: [] as string[],
  asset_type_id: "",
  asset_type_name: "",
  asset_volume_id: "",
  asset_volume_name: "",
  asset_volume_description: "",
  asset_photo: "",
  company_id: "",
  company_name: "",
  created_at: "",
  deleted_at: "",
  device_battery_percentage: 0,
  device_deep_sleep: false,
  //installed device(ZNode) id
  device_id: "",
  device_status_id: "",
  device_status_name: "",
  functioning: false,
  id: "",
  last_filling_level: 0,
  last_position_lat: 0,
  last_position_lon: 0,
  last_theft_detect: "",
  name: "",
  properties: [] as AssetProperty[],
  register_address: "",
  //installed device(ZNode) serial number
  serial_number: "",
  template_name: "",
  tracking_address: "",
  updated_at: "",
  use_case_id: "",
  use_case_name: "",
  uvv_next_check: null,
  fence_id: "",
};

const state: Asset = {
  ...initialState,
};

function formatDate(state: Asset, format: string): string | null {
  if (!state.uvv_next_check || state.uvv_next_check === "") return null;
  if (moment(state.uvv_next_check).isValid()) {
    return moment(state.uvv_next_check).format(format);
  } else {
    return null;
  }
}

const getters: GetterTree<Asset, RootState> = {
  // "YYYY.MM.DD"
  formattedUVVDate(state) {
    return formatDate(state, "YYYY-MM-DD");
  },
  // "DD.MM.YYYY"
  formattedUVVDateGerman(state) {
    return formatDate(state, "DD.MM.YYYY");
  },
  tagsAsHasNameArray: (state) => {
    return state.asset_tags.map((tag) => {
      return { name: tag };
    });
  },
};

const actions: ActionTree<Asset, RootState> = {
  fetchAsset({ commit }, id) {
    return fetchAsset(id)
      .then((asset) => {
        commit("setAsset", asset);
        return asset;
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  },
  createAsset({ commit }, partialAsset: Partial<Asset>): Promise<Asset | void> {
    return createAsset(partialAsset)
      .then((asset) => {
        commit("setAsset", asset);
        return asset;
      })
      .catch((e) => {
        showToastError(e, "Fehler beim Erstellen des Behälters");
      });
  },
  editAsset(
    { commit, state },
    partialAsset: Partial<Asset>
  ): Promise<number | void> {
    const newAsset = partialAsset as Asset;

    if (!newAsset.id) newAsset.id = state.id;
    if (!newAsset.asset_type_id) newAsset.asset_type_id = state.asset_type_id;
    if (!newAsset.asset_volume_id)
      newAsset.asset_volume_id = state.asset_volume_id;

    return editAsset(newAsset).then(() => {
      // successfully saved, mutate state accordingly
      commit("setAsset", newAsset);
    });
  },
  editTags({ commit, state }, newTags: AssetTag[]): Promise<number | void> {
    const newAsset = {
      id: state.id,
      asset_type_id: state.asset_type_id,
      asset_volume_id: state.asset_volume_id,
      asset_tags: newTags,
    } as Asset;

    return editAsset(newAsset).then(() => {
      // successfully saved, mutate state accordingly
      commit("setAsset", newAsset);
    });
  },
  showOnMap({ state }): void {
    if (
      state.last_position_lat &&
      state.last_position_lat !== 0 &&
      state.last_position_lon &&
      state.last_position_lon !== 0
    ) {
      router
        .push({
          path: "/",
          query: {
            lat: state.last_position_lat.toString(),
            lon: state.last_position_lon.toString(),
            zoom: "14",
          },
        })
        .catch((err) => err);
    }
  },
};

const mutations: MutationTree<Asset> = {
  setAsset(state, payload: Asset) {
    Object.assign(state, payload);
  },
  setTags(state, assetTags: string[]) {
    state.asset_tags = assetTags;
  },
  setConditionId(state, conditionId: string) {
    state.asset_condition_id = conditionId;
  },
  setConditionName(state, conditionName: string) {
    state.asset_condition_name = conditionName;
  },
  setCleaningId(state, cleaningId: string) {
    state.asset_cleaning_id = cleaningId;
  },
  setCleaningName(state, cleaningName: string) {
    state.asset_cleaning_name = cleaningName;
  },
  setVolumeName(state, volumeName: string) {
    state.asset_volume_name = volumeName;
  },
  setVolumeDescription(state, description: string) {
    state.asset_volume_description = description;
  },
  setVolumeId(state, volumeId: string) {
    state.asset_volume_id = volumeId;
  },
  setContentMaterialId(state, contentMaterialId: string) {
    state.asset_content_material_id = contentMaterialId;
  },
  setContentMaterialName(state, contentMaterialName: string) {
    state.asset_content_material_name = contentMaterialName;
  },
  setLastFillingLevel(state, lastFillingLevel: number) {
    state.last_filling_level = lastFillingLevel;
  },
  setSerialNumber(state, serialNumber: string) {
    state.serial_number = serialNumber;
  },
  //sets state to initialState with all values reset
  reset(state) {
    Object.assign(state, initialState);
  },
};

const asset: Module<Asset, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
export default asset;
