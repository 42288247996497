import { AvalUnit } from "@/types/aval/aval_catalog";

export const aval_weights = [
  {
    id: "AE.1.100",
    description: "mg",
    valid_from: "01.01.2020",
    valid_until: "<unbegrenzt>",
  },
  {
    id: "AE.1.101",
    description: "g",
    valid_from: "01.01.2020",
    valid_until: "<unbegrenzt>",
  },
  {
    id: "AE.1.102",
    description: "kg",
    valid_from: "01.01.2020",
    valid_until: "<unbegrenzt>",
  },
  {
    id: "AE.1.103",
    description: "t",
    valid_from: "01.01.2020",
    valid_until: "<unbegrenzt>",
  },
] as AvalUnit[];

export const aval_times = [
  {
    id: "AE.1.300",
    description: "Zeit sec",
    valid_from: "01.01.2020",
    valid_until: "<unbegrenzt>",
  },
  {
    id: "AE.1.301",
    description: "Zeit min",
    valid_from: "01.01.2020",
    valid_until: "<unbegrenzt>",
  },
  {
    id: "AE.1.302",
    description: "Zeit h",
    valid_from: "01.01.2020",
    valid_until: "<unbegrenzt>",
  },
  {
    id: "AE.1.303",
    description: "Zeit Tag",
    valid_from: "01.01.2020",
    valid_until: "<unbegrenzt>",
  },
  {
    id: "AE.1.304",
    description: "Zeit Monat",
    valid_from: "01.01.2020",
    valid_until: "<unbegrenzt>",
  },
  {
    id: "AE.1.305",
    description: "Zeit Quartal",
    valid_from: "01.01.2020",
    valid_until: "<unbegrenzt>",
  },
  {
    id: "AE.1.306",
    description: "Zeit Halbjahr",
    valid_from: "01.01.2020",
    valid_until: "<unbegrenzt>",
  },
  {
    id: "AE.1.307",
    description: "Zeit Jahr",
    valid_from: "01.01.2020",
    valid_until: "<unbegrenzt>",
  },
] as AvalUnit[];

export const aval_amount = [
  {
    id: "AE.1.400",
    description: "Anzahl Stück",
    valid_from: "01.01.2020",
    valid_until: "<unbegrenzt>",
  },
  {
    id: "AE.1.401",
    description: "Anzahl pauschal",
    valid_from: "01.01.2020",
    valid_until: "<unbegrenzt>",
  },
] as AvalUnit[];

export const aval_distance = [
  {
    id: "AE.1.500",
    description: "Strecke m",
    valid_from: "01.01.2020",
    valid_until: "<unbegrenzt>",
  },
  {
    id: "AE.1.501",
    description: "Strecke km",
    valid_from: "01.01.2020",
    valid_until: "<unbegrenzt>",
  },
  {
    id: "AE.1.600",
    description: "Fläche qm",
    valid_from: "01.01.2020",
    valid_until: "<unbegrenzt>",
  },
] as AvalUnit[];
