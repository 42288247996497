var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"uppy-wrapper"}},[_c('dashboard',{ref:"dashboard",attrs:{"id":"uppy-dashboard","props":{
      theme: _vm.$vuetify.theme.dark ? 'dark' : 'light',
      width: '100%',
      height: _vm.height,
      inline: true,
      proudlyDisplayPoweredByUppy: false,
      displayThumbnailGenerator: true,
      showRemoveButtonAfterComplete: true,
      disableLocalFiles: false,
      hideUploadButton: true,
      locale: {
        strings: {
          dropPasteFiles: _vm.title,
          myDevice: _vm.optionTitle,
        },
      },
    },"uppy":_vm.uppy}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }