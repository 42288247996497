import Vue from "vue";
import VueSession from "vue-session";
import App from "./App.vue";
import VueGtag from "vue-gtag";
import router from "./router";
import citizenRouter from "./router/citizen";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "@mdi/font/css/materialdesignicons.min.css";
import { abilitiesPlugin } from "@casl/vue";
import { AppAbility } from "./plugins/ability";
import registerAxiosInterceptors from "@/plugins/axios";
import registerGMapAutocomplete from "@/plugins/gMapAutocomplete";
import CitizenParticipation from "@/CitizenParticipation.vue";
import { Route } from "vue-router";

declare module "vue/types/vue" {
  interface Vue {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    $session: any;
  }
}

Vue.config.productionTip = false;

//Create new, empty (CASL) Ability object which will later be available on $ability
//After user login this is initialized with user-specific accessPolicy
Vue.use(abilitiesPlugin, new AppAbility());
Vue.use(VueSession, { persist: true });
// Axios is dependent on Session & Router and
// needs to be initialized before Router is registered on the current Vue instance.
registerAxiosInterceptors();
registerGMapAutocomplete();

if (process.env.NODE_ENV !== "development") {
  Vue.use(
    VueGtag,
    {
      config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID },
      appName: process.env.VUE_APP_NAME,
      pageTrackerScreenviewEnabled: false,
      pageTrackerTemplate(to: Route) {
        return {
          page_title: to.meta?.title,
          page_path: to.path,
        };
      },
    },
    router
  );
}

/**
 * If the current path contains something related to citizin participation,
 * e.g. /buerger/ create "lite" version of the app. Otherwise, create the full version.
 * This is done to reduce the size of the app for citizen participation mobile access.
 */

//create empty vue instance
let vue: Vue;
const path = window.location.pathname;

//if the current path contains something related to citizin participation,
// e.g. /buerger/ create "lite" version of the app
if (path.includes("/buerger/")) {
  vue = new Vue({
    router: citizenRouter,
    //Todo: Own store for citizen participation
    store,
    vuetify,
    render: (h) => h(CitizenParticipation),
  });
} else {
  vue = new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  });
}
vue.$mount("#app");
