import { Dictionary } from "vue-router/types/router";
import { camelCase, isArray, isEmpty, isObject, transform } from "lodash";
import Vue from "vue";
import { aval_weights } from "@/assets/settings/aval_units";
import { AvalUnit } from "@/types/aval/aval_catalog";
import { HasName } from "@/types/vue/mixins";
import { AxiosError } from "axios";
import { GMapLatLng } from "@/types/fence/fence";
import { DataOptions } from "vuetify";
import { Buffer } from "buffer";

const delayKeyUp = (() => {
  let timer: number | undefined;
  return (func: () => unknown, ms: number) => {
    timer ? clearTimeout(timer) : null;
    timer = setTimeout(() => func(), ms) as unknown as number;
  };
})();

const showToastError = (e?: AxiosError, message?: string) => {
  Vue.prototype.$toast(message || (e ? e.message : ""), {
    color: "red",
    dismissable: true,
    queueable: true,
  });
};
const showToastWarning = (message: string) => {
  Vue.prototype.$toast(message, {
    color: "orange",
    dismissable: true,
    queueable: true,
  });
};
const showToastSuccess = (message: string) => {
  Vue.prototype.$toast.success(message, {
    dismissable: true,
    queueable: false,
  });
};

/**
 * @summary Uses browser API to get current Position.
 * @returns Promise with current position.
 * @throws Error if browser does not support geolocation or it has been denied.
 */
const getCurrentPosition = (): Promise<GMapLatLng | null> => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        resolve({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      (err) => {
        //in case of denial of location access, set default position
        reject(err.message);
      }
    );
  });
};

const getColorForFillingLevel = (level?: number) => {
  if (!level) return "none";
  if (level < 50) return "green";
  if (level < 90) return "amber";
  return "red";
};

const getColorForBatteryLevel = (fillingLevel: number | null) => {
  if (!fillingLevel) return "black";
  if (fillingLevel > 70) return "green";
  if (fillingLevel > 30) return "amber";
  return "red";
};

const replaceById = function <T extends HasName>(
  target: T[],
  source: T,
  id: string
) {
  const i = target.findIndex((obj) => obj.id === id);
  if (i > -1) Object.assign(target[i], source);
};

/**
 * @summary Substitutes path parameters with their corresponding key.
 *
 * @description The user's permissions of modules are saved with the path of the route(excluding dynamic url contents).
 * If the route has dynamic path parameters (e.g. ':id') we need to substitute the id with a generic placeholder for rule comparison.
 *
 * @param routeParams The current modules params, like "id". Found in $route.params
 * @param routePath The current modules path, like "/asset-management/assets/". Found in $route.path
 * @returns The substituted route's path.
 */
function substitudePathId(
  routeParams: Dictionary<string>,
  routePath: string
): string {
  let substitutePath = routePath;
  if (!isEmpty(routeParams)) {
    //replace dynamic part of path with the dynamic part's name
    for (const key in routeParams) {
      //e.g. "/asset-management/assets/8cb8b9e5-eedd-4caf-be85-dfhj37321d" -> "/asset-management/assets/:id"
      substitutePath = routePath.replace(routeParams[key], `:${key}`);
    }
  }
  return substitutePath;
}

function createAssetTag(tag: HasName): Promise<Partial<HasName>> {
  //No CRUD-operations on asset-tags available in API - just add to list
  return Promise.resolve(tag as Partial<HasName>);
}

/**
 * @name findParentComponentByName
 * @summary Find the Vue instance of the first parent component that matches the provided component name.
 *
 * @description The `findParentComponentByName()` method returns the Vue instance of the first parent component
 * that has a `name` component option that matches the provided component name.
 *
 * @param {Vue} vm - The children component Vue instance that is looking for the parent component Vue instance
 * @param {string} componentName - The parent component name
 * @returns {Vue|undefined} The parent component instance that matches the provided component name,
 * otherwise, undefined is returned
 *
 * @example
 * // Find `<App/>` component from `<Child/>`:
 * <App>
 *   <GrandParent>
 *     <Parent>
 *       <Child/>
 *     </Parent>
 *   </GrandParent>
 * </App>
 *
 * // Descendant component Vue instance
 * new Vue({
 *   name: 'Child',
 *
 *   created() {
 *     const app = findParentComponentByName(this, 'App')
 *     // => VueComponent {_uid: 1, _isVue: true, $options: {…}, _renderProxy: Proxy, _self: VueComponent, …}
 *  },
 * })
 */
function findParentComponentByName(
  vm: Vue,
  componentName: string
): Vue | undefined {
  //
  //  Components tree:
  //  +---------------------+  \ return undefined, , <Child/> is not a descendant of <App/> \
  //  | <App>               |---> Return if component name option matches, otherwise
  //  |---------------------|    \ continue traversing the tree upwards \
  //  |   <GrandParent>     |-----> Return if component name option matches, otherwise
  //  |---------------------|      \ continue traversing the tree upwards \
  //  |     <Parent>        |-------> Return if component name option matches, otherwise
  //  |---------------------|        \ traverse the tree upwards \
  //  |       <Child/>      |---------> STARTING POINT, start looking for App component from here
  //  |---------------------|
  //  |     </Parent>       |
  //  |---------------------|
  //  |   </GrandParent>    |
  //  |---------------------|
  //  | </App>              |
  //  +---------------------+
  //

  let component: Vue | undefined;
  let parent = vm.$parent;

  while (parent && !component) {
    if (parent.$options.name === componentName) {
      component = parent;
    }
    parent = parent.$parent;
  }

  return component;
}

function getAvalWeight(id: string): AvalUnit | undefined {
  return aval_weights.find((w) => w.id === id);
}

/**
 * @summary Converts a time string with format HH:mm to seconds from 00:00:00
 * @param str The time string to convert
 */
function hmsToSeconds(str: string): number {
  const hms = str.split(":");
  let seconds = 0,
    m = 60;

  // format is HH:mm:ss
  if (hms.length > 3) {
    m = 1;
  }

  // format is HH:mm, use m60 to convert hours to seconds.
  while (hms.length > 0) {
    seconds += m * parseInt(hms.pop() ?? "0", 10);
    m *= 60;
  }

  return seconds;
}

const camelize = (obj: Record<string, unknown>) =>
  transform(
    obj,
    (result: Record<string, unknown>, value: unknown, key: string, target) => {
      const camelKey = isArray(target) ? key : camelCase(key);
      result[camelKey] = isObject(value)
        ? camelize(value as Record<string, unknown>)
        : value;
    }
  );

const trimToLength = function (string: string, length: number): string | null {
  if (string && string.length >= length) {
    return string.slice(0, length) + "...";
  } else {
    return string ?? null;
  }
};

export function delay(t: number) {
  return new Promise((resolve) => setTimeout(resolve, t));
}

/**
 * Maps v-router query params to a vuetify table options configuration and returns it.
 * @param query The v-router query params
 * @return Partial vuetify table options configuration, defaulting unassigned values to undefined
 */
const vTableOptionsFromQuery = function (
  query: Dictionary<string | (string | null)[]>
): Partial<DataOptions> {
  return {
    itemsPerPage: query.limit ? parseInt(query.limit as string) : undefined,
    page: query.page ? parseInt(query.page as string) : undefined,
    sortBy: query.sort ? [query.sort as string] : undefined,
    sortDesc: query.sort && query.sort.includes("-") ? [true] : undefined,
  };
};

const copyToClipboard = (value: string) => {
  navigator.clipboard
    .writeText(value)
    .then(() => {
      showToastSuccess("In die Zwischenablage kopiert.");
    })
    .catch(() => {
      showToastWarning("Fehler beim Kopieren in die Zwischenablage.");
    });
};

const base64Decode = (str: string): string => {
  return Buffer.from(str, "base64").toString("utf-8");
};

const getTodayIsoDateString = (): string => {
  const today = new Date();
  return today.toISOString();
};

const getTodayLastYearIsoDateString = (): string => {
  const today = new Date();
  today.setFullYear(today.getFullYear() - 1);
  return today.toISOString();
};

const getTodayNextYearIsoDateString = (): string => {
  const today = new Date();
  today.setFullYear(today.getFullYear() + 1);
  return today.toISOString();
};

export {
  delayKeyUp,
  showToastError,
  showToastSuccess,
  showToastWarning,
  getColorForFillingLevel,
  getColorForBatteryLevel,
  substitudePathId,
  createAssetTag,
  hmsToSeconds,
  findParentComponentByName,
  replaceById,
  getAvalWeight,
  getCurrentPosition,
  camelize,
  trimToLength,
  copyToClipboard,
  vTableOptionsFromQuery,
  base64Decode,
  getTodayIsoDateString,
  getTodayLastYearIsoDateString,
  getTodayNextYearIsoDateString,
};
