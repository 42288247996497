import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState, VehicleProperties } from "@/store/types";
import {
  PTVDrivingProfile,
  Vehicle,
  VehiclePart,
  VehiclePartManufacturer,
  VehicleType,
} from "@/types/recycling-manager/vehicle";
import { vehicleStatuses } from "@/assets/settings/statuses";
import { find } from "lodash";
import { HasName } from "@/types/vue/mixins";
import { showToastError, showToastSuccess } from "@/plugins/utilities";
import {
  createVehicle,
  deleteMultiple,
  editVehicle,
  fetchBodyMounts,
  fetchChassisManufacturers,
  fetchLifterManufacturers,
  fetchLifters,
  fetchMountManufacturers,
  fetchVehicles,
  fetchVehicleTypes,
} from "@/api/server";

const initialState: VehicleProperties = {
  vehicles: [] as Vehicle[],
  body_mounts: [] as VehiclePart[],
  lifters: [] as VehiclePart[],
  chassis_manufacturers: [] as VehiclePartManufacturer[],
  lifter_manufacturers: [] as VehiclePartManufacturer[],
  mount_manufacturers: [] as VehiclePartManufacturer[],
  driving_profiles: [] as PTVDrivingProfile[],
  // Disabled as model field was changed from select to text
  // models: [] as VehicleModel[],
  statuses: vehicleStatuses as HasName[],
  types: [] as VehicleType[],
};

const state: VehicleProperties = {
  ...initialState,
};

const getters: GetterTree<VehicleProperties, RootState> = {
  getVehicles: (state) => {
    return state.vehicles;
  },
  getStatus: (state) => (id: string) => {
    return find(state.statuses, { id: id });
  },
  getBodyMount: (state) => (id: string) => {
    return find(state.body_mounts, { id: id });
  },
  getBodyMountManufacturer: (state) => (id: string) => {
    return find(state.mount_manufacturers, { id: id });
  },
  getDrivingProfile: (state) => (name: string) => {
    return find(state.driving_profiles, { name: name });
  },
};

//TODO: replace existence check with subscribeAction & before
const actions: ActionTree<VehicleProperties, RootState> = {
  fetchVehicles({ state, commit }): Promise<Partial<Vehicle>[]> {
    if (state.vehicles && state.vehicles.length)
      return Promise.resolve(state.vehicles);
    return fetchVehicles()
      .then((vehicles) => {
        commit("setVehicles", vehicles);
        return vehicles;
      })
      .catch((e) => {
        showToastError(e, "Fehler beim laden der Fahrzeuge");
        return state.vehicles;
      });
  },
  createVehicle({ commit }, vehicle: Partial<Vehicle>) {
    return createVehicle(vehicle)
      .then((newVehicle) => {
        commit("addVehicle", newVehicle);
        return newVehicle;
      })
      .catch((e) => {
        showToastError(e, "Fehler beim Erstellen des Fahrzeugs");
        throw e;
      });
  },
  editVehicle({ commit }, vehicle: Partial<Vehicle>): Promise<void> {
    if (!vehicle.id) return Promise.reject("No vehicle ID");
    const vehicleCopy = { ...vehicle };
    delete vehicleCopy.id;

    return editVehicle(vehicle.id, vehicleCopy)
      .then(() => {
        commit("updateVehicle", vehicle);
        showToastSuccess("Fahrzeug aktualisiert");
      })
      .catch((e) => {
        showToastError(e, "Fehler beim Bearbeiten des Fahrzeugs");
        throw e;
      });
  },
  deleteVehicles({ commit }, vehicles: Vehicle[]) {
    deleteMultiple(process.env.VUE_APP_ASSET_ || "", "vehicles", vehicles)
      .then(() => {
        commit("removeVehicles", vehicles);
      })
      .catch((e) => {
        showToastError(e, "Fehler beim Löschen der Fahrzeuge");
        throw e;
      });
  },
  // fetchModels({ state, commit }): Promise<VehicleModel[]> {
  //   if (state.models && state.models.length)
  //     return Promise.resolve(state.models);
  //   return //     .fetchVehicleModels()
  //     .then(models => {
  //       commit("setModels", models);
  //       return models;
  //     })
  //     .catch(e => {
  //       showAxiosError(e, "Fehler beim laden der Fahrzeug-Modelle");
  //       return state.models;
  //     });
  // },
  fetchBodyMounts({ state, commit }): Promise<VehiclePart[]> {
    if (state.body_mounts && state.body_mounts.length)
      return Promise.resolve(state.body_mounts);
    return fetchBodyMounts()
      .then((bodyMounts) => {
        commit("setBodyMounts", bodyMounts);
        return bodyMounts;
      })
      .catch((e) => {
        showToastError(e, "Fehler beim laden der Fahrzeug-Aufbauten");
        return state.body_mounts;
      });
  },
  fetchMountManufacturers({ state, commit }) {
    if (state.mount_manufacturers && state.mount_manufacturers.length)
      return Promise.resolve(state.mount_manufacturers);
    return fetchMountManufacturers()
      .then((manufacturers) => {
        commit("setMountManufacturers", manufacturers);
        return manufacturers;
      })
      .catch((e) => {
        showToastError(e, "Fehler beim laden der Fahrzeug-Aufbau-Hersteller");
        return state.mount_manufacturers;
      });
  },
  fetchLifters({ state, commit }): Promise<VehiclePart[]> {
    if (state.lifters && state.lifters.length)
      return Promise.resolve(state.lifters);
    return fetchLifters()
      .then((lifters) => {
        commit("setLifters", lifters);
        return lifters;
      })
      .catch((e) => {
        showToastError(e, "Fehler beim laden der Fahrzeug-Lifter");
        return state.lifters;
      });
  },
  fetchLifterManufacturers({ state, commit }) {
    if (state.lifter_manufacturers && state.lifter_manufacturers.length)
      return Promise.resolve(state.lifter_manufacturers);
    return fetchLifterManufacturers()
      .then((manufacturers) => {
        commit("setLifterManufacturers", manufacturers);
        return manufacturers;
      })
      .catch((e) => {
        showToastError(e, "Fehler beim laden der Fahrzeug-Lifter-Hersteller");
        return state.lifter_manufacturers;
      });
  },
  fetchChassisManufacturers({
    state,
    commit,
  }): Promise<VehiclePartManufacturer[]> {
    if (state.chassis_manufacturers && state.chassis_manufacturers.length)
      return Promise.resolve(state.chassis_manufacturers);
    return fetchChassisManufacturers()
      .then((manufacturers) => {
        commit("setChassisManufacturers", manufacturers);
        return manufacturers;
      })
      .catch((e) => {
        showToastError(e, "Fehler beim laden der Fahrzeug-Chassis-Hersteller");
        return state.chassis_manufacturers;
      });
  },
  fetchTypes({ state, commit }): Promise<VehicleType[]> {
    if (state.types && state.types.length) return Promise.resolve(state.types);
    return fetchVehicleTypes()
      .then((types) => {
        commit("setTypes", types);
        return types;
      })
      .catch((e) => {
        showToastError(e, "Fehler beim laden der Fahrzeug-Typen");
        return state.types;
      });
  },

  // fetchDrivingProfiles({ state, commit }) {
  //   if (state.driving_profiles && state.driving_profiles.length)
  //     return Promise.resolve(state.driving_profiles);
  //   return //     .fetchDrivingProfiles()
  //     .then(profiles => {
  //       commit("setDrivingProfiles", profiles);
  //       return profiles;
  //     })
  //     .catch(e => {
  //       showToastError(e, "Fehler beim laden der Fahrprofile");
  //       return state.body_mounts;
  //     });
  // }
};

const mutations: MutationTree<VehicleProperties> = {
  setVehicles(state, vehicles: Vehicle[]) {
    state.vehicles = vehicles;
  },
  addVehicle(state, vehicle: Vehicle) {
    state.vehicles.push(vehicle);
  },
  updateVehicle(state, vehicle: Partial<Vehicle>) {
    const i = state.vehicles.findIndex((obj) => obj.id === vehicle.id);
    if (i > -1) Object.assign(state.vehicles[i], vehicle);
  },
  removeVehicles(state, vehicles: Vehicle[]) {
    state.vehicles = state.vehicles.filter(
      (vehicle) => !vehicles.some((v) => v.id === vehicle.id)
    );
  },
  // setModels(state, models: VehicleModel[]) {
  //   state.models = models;
  // },
  setBodyMounts(state, bodyMounts: VehiclePart[]) {
    state.body_mounts = bodyMounts;
  },
  setMountManufacturers(state, manufacturers: VehiclePartManufacturer[]) {
    state.mount_manufacturers = manufacturers;
  },
  setLifters(state, lifters: VehiclePart[]) {
    state.lifters = lifters;
  },
  setLifterManufacturers(state, manufacturers: VehiclePartManufacturer[]) {
    state.lifter_manufacturers = manufacturers;
  },
  setChassisManufacturers(state, manufacturers: VehiclePartManufacturer[]) {
    state.chassis_manufacturers = manufacturers;
  },
  setTypes(state, types: VehicleType[]) {
    state.types = types;
  },
  setDrivingProfiles(state, profiles: PTVDrivingProfile[]) {
    state.driving_profiles = profiles;
  },
};

const vehicleProperties: Module<VehicleProperties, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
export default vehicleProperties;
