import Vue from "vue";
import VueRouter from "vue-router";
import { isEmpty } from "lodash";
import store from "@/store";
import { UserAccount } from "@/types/user-management/user-account";
import axios, { AxiosError } from "axios";
import { defineRulesFor } from "@/plugins/ability";
import { substitudePathId } from "@/plugins/utilities";
import { Permission } from "@/types/user-management/access-policy";

Vue.use(VueRouter);
/**
 * @description This is the main router of the application.
 * @see https://router.vuejs.org/guide/#html
 *
 * @example
 * //Meta attributes:
 * meta: {
 *  title: string, // title of the page, normally displayed in header
 *
 *  category: string, // category of the page, used to group pages in Menu (NavigationDrawer)
 *  If no category is set, the page will not be displayed in the Menu
 *
 *  icon: string, // icon of the menu item
 *  minAccessLevel: number, // minimum accessLevel in UserAccount to access the page (0 is highest)
 *  hasCards: boolean, // if the page contains cards, e.g. ImageCard. Used by ModulesView to determine module name.
 * }
 */
const routes = [
  {
    path: "/",
    name: "HomeMap",
    component: () => import("@/views/home/HomeMap.vue"),
    meta: {
      icon: "mdi-google-maps",
      title: "Karte",
      category: "Start",
    },
  },
  {
    path: "/login",
    name: "LoginView",
    component: () => import("@/views/login/LoginView.vue"),
    meta: {
      noAuth: true,
      title: "Login",
      showNav: false,
    },
  },
  //Jobmanagement
  {
    path: "/asset-management/jobs",
    name: "JobsView",
    component: () => import("@/views/asset-management/jobs/JobTable.vue"),
    meta: {
      icon: "mdi-file-check-outline",
      title: "Aufträge",
      category: "Auftragsverwaltung",
    },
  },
  //asset-management
  {
    path: "/asset-management/assets",
    name: "AssetTable",
    component: () =>
      import("@/views/asset-management/assets/AssetTableView.vue"),
    meta: {
      icon: "mdi-delete",
      title: "Behälter",
      category: "Stammdaten-Verwaltung",
    },
  },
  {
    path: "/asset-management/assets/:id",
    name: "AssetDetail",
    component: () => import("@/views/asset-management/assets/AssetDetail.vue"),
    meta: {
      title: "Behälter-Detail",
      hasCards: true,
    },
    props: true,
  },
  {
    path: "/asset-management/devices",
    name: "DeviceTableView",
    component: () =>
      import("@/views/asset-management/devices/DeviceTableView.vue"),
    meta: {
      icon: "mdi-devices",
      title: "Z-Nodes",
      category: "Stammdaten-Verwaltung",
    },
  },
  {
    path: "/asset-management/locations",
    name: "LocationsView",
    component: () =>
      import("@/views/asset-management/locations/LocationTable.vue"),
    meta: {
      icon: "mdi-map-marker-circle",
      title: "Standorte",
      category: "Stammdaten-Verwaltung",
    },
  },
  {
    path: "/asset-management/locations/:id",
    name: "LocationDetailView",
    component: () =>
      import("@/views/asset-management/locations/LocationDetail.vue"),
    meta: {
      minAccessLevel: 3,
      title: "Standort-Detail",
    },
    props: true,
  },
  {
    path: "/asset-management/vehicles",
    name: "Vehicles",
    component: () => import("@/views/asset-management/vehicles/Vehicles.vue"),
    meta: {
      icon: "mdi-truck",
      title: "Fahrzeuge",
      category: "Stammdaten-Verwaltung",
    },
  },
  {
    path: "/asset-management/vehicles/:id",
    name: "VehicleDetail",
    component: () =>
      import("@/views/asset-management/vehicles/VehicleDetail.vue"),
    meta: {
      title: "Fahrzeug-Detail",
      hasCards: true,
    },
    props: true,
  },
  {
    path: "/asset-management/columns",
    name: "Column",
    component: () => import("@/views/asset-management/column/Columns.vue"),
    meta: {
      icon: "mdi-account-group-outline",
      title: "Kolonnen",
      category: "Stammdaten-Verwaltung",
    },
  },
  {
    path: "/asset-management/columns/:id",
    name: "ColumnDetail",
    component: () => import("@/views/asset-management/column/ColumnDetail.vue"),
    meta: {
      title: "Kolonne-Detail",
      hasCards: true,
    },
    props: true,
  },
  {
    path: "/customer-management/accounts",
    name: "AccountsView",
    component: () =>
      import("@/views/customer-management/accounts/AccountsView.vue"),
    meta: {
      icon: "mdi-account-multiple",
      title: "Mitarbeiter",
      category: "Stammdaten-Verwaltung",
    },
  },

  //asset-report
  {
    path: "/reports/assets-pre-empty-filling-level",
    name: "AssetPreEmptyFillingLevelView",
    component: () =>
      import("@/views/reports/AssetPreEmptyFillingLevelView.vue"),
    meta: {
      icon: "mdi-chart-line",
      title: "Effizienz",
      category: "Berichte",
    },
  },
  {
    path: "/reports",
    name: "ParticipationReports",
    component: () => import("@/views/reports/Reports.vue"),
    meta: {
      icon: "mdi-alert-circle-outline",
      title: "Meldungen",
      category: "Berichte",
    },
    children: [
      {
        path: "app",
        name: "AppReports",
        component: () => import("@/views/reports/AppReports.vue"),
        meta: {
          title: "Mitarbeiter",
        },
      },
      {
        path: "citizens",
        name: "CitizenReports",
        component: () => import("@/views/reports/CitizenReports.vue"),
        meta: {
          title: "Bürgerbeteiligung",
        },
      },
    ],
  },

  {
    path: "/customer-management/companies",
    name: "CompaniesView",
    component: () =>
      import("@/views/customer-management/companies/CompaniesView.vue"),
    meta: {
      icon: "mdi-domain",
      title: "Unternehmen",
      category: "Kundenverwaltung",
    },
  },

  //misc
  {
    path: "/customer-management/permissions",
    component: () =>
      import("@/views/customer-management/permissions/index.vue"),
    name: "PermissionsView",
    meta: {
      // or mdi-scale-balance
      icon: "mdi-account-cog",
      title: "Berechtigungen",
      category: "Sonstiges",
    },
    children: [
      {
        path: "roles",
        name: "RolesView",
        component: () =>
          import("@/views/customer-management/permissions/roles/RolesView.vue"),
        meta: {
          icon: "mdi-arrow-decision",
          title: "Rollen",
        },
      },
      {
        path: "modules",
        name: "ModulesView",
        component: () =>
          import(
            "@/views/customer-management/permissions/modules/ModulesView.vue"
          ),
        meta: {
          icon: "mdi-arrow-decision",
          title: "Module",
        },
      },
    ],
  },
  {
    path: "/misc/keys",
    name: "KeysView",
    component: () => import("@/views/misc/KeysView.vue"),
    meta: {
      icon: "mdi-key",
      title: "API-Schlüssel (alle)",
      category: "Sonstiges",
      description: "API-Schlüssel für alle Unternehmen",
    },
  },
  {
    path: "/misc/company-keys",
    name: "CompanyKeysView",
    component: () => import("@/views/misc/CompanyKeysView.vue"),
    meta: {
      icon: "mdi-key",
      title: "API-Schlüssel",
      category: "Sonstiges",
      description: "API-Schlüssel für das Unternehmen des aktuellen Benutzers",
    },
  },
  {
    path: "/misc/contact",
    name: "ContactView",
    component: () => import("@/views/misc/ContactView.vue"),
    meta: {
      icon: "mdi-card-account-mail",
      title: "Ansprechpartner",
      category: "Sonstiges",
    },
  },
  {
    path: "/misc/downloads",
    name: "DownloadView",
    component: () => import("@/views/misc/DownloadView.vue"),
    meta: {
      icon: "mdi-download",
      title: "Downloads",
      category: "Sonstiges",
    },
  },
  {
    path: "/misc/settings",
    name: "SettingsView",
    component: () => import("@/views/misc/SettingsView.vue"),
    meta: {
      icon: "mdi-cog",
      title: "Einstellungen",
      category: "Sonstiges",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.noAuth === true)) {
    //If no login is required, directly go there
    next();
    return;
    //If requested page does not exist, go to '/'
  } else if (to.matched.length === 0) next({ path: "/" });

  //If a login is required, check if a session exists - otherwise goto login page
  if (!router.app.$session.exists()) {
    next({
      path: "/login",
      query: { redirect: to.fullPath },
    });
    return;
  }

  const ability = Vue.prototype.$ability;

  //Check if there are present CASL auth rules
  if (isEmpty(ability.rules)) {
    // This occurs if a page is opened directly via url
    // To create rules, initialize existing instance of ability with accessPolicy from current user
    let user = store.getters["user/user"] as UserAccount;
    if (!user.id) {
      // If there is no user stored, try to fetch it using the saved jwt
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + router.app.$session.get("accessToken");
      user = await store.dispatch("user/fetchUser").catch((e: AxiosError) => {
        if (e.response && e.response.status && e.response.status === 401) {
          //Unauthorized, probably not logged in.
          next({ path: "/login" });
        }
      });
    }

    //Define ruleset for user which controls all access
    if (user) {
      //User initialized, define rules based on his role + modules
      //Navigate further as planned
      ability.update(defineRulesFor(user));
      next();
    } else if (from.name !== "LoginView") {
      //No user present - if not already on login page, redirect to it
      next({ path: "/login", query: { redirect: to.fullPath } });
    }
    //...Probably on login page, do noting
    return;
  }

  //Is user allowed to navigate to selected (to.path) route?
  const toPath = substitudePathId(to.params, to.path);
  //TODO: remove toPath === "/", redirect to first accessible page for this user.
  // (maybe in login, where initial redirect happens after login)
  if (toPath === "/" || ability.can(Permission.read, toPath)) {
    next();
  } else {
    //cancel navigation if not authorized
    return false;
  }

  next();
});

router.afterEach((to) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    //If the route path contains an :id, it's replaced with the object's name in the detail-view
    document.title = to.meta?.title || "";
  });
});

export default router;
