import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState, UserManagement } from "../types";

import {
  createAccount,
  createCompany,
  createDuty,
  createModule,
  createRole,
  deleteDuty,
  deleteModule,
  deleteRole,
  editAccount,
  editCompany,
  editDuty,
  editModule,
  editRole,
  fetchAccountById,
  fetchAccounts,
  fetchDuties,
  fetchModule,
  fetchModules,
  fetchRole,
  fetchRoles,
  fetchRoutes,
  removeAccount,
} from "@/api/server/index";
import Company from "@/types/company";
import AccessLevel from "@/types/access-level";
import UseCase from "@/types/asset/use-case";
import { RbacModule, Role, Route } from "@/types/user-management/access-policy";
import { UserAccount } from "@/types/user-management/user-account";
import { orderBy, sortBy } from "lodash";
import { Duty } from "@/types/recycling-manager/duty";
import { showToastError, showToastSuccess } from "@/plugins/utilities";
import { AxiosError } from "axios";
import {
  fetchAccessLevels,
  fetchCompanies,
  fetchUseCases,
  removeCompany,
} from "@/api/server";
import { GenericError } from "@/types/error/account-api";

//TODO: Companies, accounts, useCases into own store (or rootState) or only in views/vue components
const state: UserManagement = {
  accounts: [] as UserAccount[],
  accessLevels: [] as AccessLevel[],
  companies: [] as Company[],
  useCases: [] as UseCase[],
  duties: [] as Duty[],
  roles: [] as Role[],
  routes: [] as Route[],
  modules: [] as RbacModule[],
};

function filterAccountsByCompanyId(
  userAccounts: UserAccount[],
  companyId: string | undefined
) {
  if (!companyId) return [];
  return userAccounts.filter((acc) => acc.Company.id === companyId);
}

const getters: GetterTree<UserManagement, RootState> = {
  getFilteredAccounts:
    (state, getters, rootState) =>
    (excludedAccounts: Partial<UserAccount>[]) => {
      if (!excludedAccounts) return state.accounts;
      return filterAccountsByCompanyId(
        state.accounts,
        rootState.user.Company.id
      ).filter((acc) => {
        return !excludedAccounts.some((wAcc) => {
          return acc.id === wAcc.id;
        });
      });
    },
  getAccountsByCompany: (state) => (companyId: string) => {
    return filterAccountsByCompanyId(state.accounts, companyId);
  },
  getAccountById: (state) => (accountId: string) => {
    return state.accounts.find((acc) => acc.id === accountId);
  },
  getAccountDepartmentsByAccId: (state) => (accountId: string) => {
    const account = state.accounts.find((acc) => acc.id === accountId);
    return account?.departments || [];
  },
  getRoutesWithAppName: (state) => (appName: string) => {
    return state.routes.filter((route) => route.appname === appName);
  },
  getCompanies: (state) => {
    return state.companies;
  },
};

const actions: ActionTree<UserManagement, RootState> = {
  fetchAccounts({ state, commit }): Promise<UserAccount[]> {
    if (state.accounts.length > 0) return Promise.resolve(state.accounts);
    return fetchAccounts().then((accounts: UserAccount[]) => {
      commit("setAccounts", accounts);
      return accounts;
    });
  },
  fetchAccountById({ state }, accountId: string): Promise<UserAccount> {
    const account = state.accounts.find((acc) => acc.id === accountId);
    if (account) return Promise.resolve(account);
    return fetchAccountById(accountId).then((account: UserAccount) => {
      return account;
    });
  },
  createAccount(
    { commit },
    account: UserAccount
  ): Promise<Partial<UserAccount>> {
    return createAccount(account as UserAccount)
      .then((newAcc) => {
        commit("addAccount", newAcc);
        showToastSuccess("Neuer Mitarbeiter angelegt.");
        return newAcc;
      })
      .catch((err: AxiosError) => {
        const errResponse = err.response?.data as GenericError;

        if (errResponse && errResponse.error) {
          if (errResponse.error === "UniqueConstraintError") {
            showToastError(
              err,
              "Ein Mitarbeiter mit dieser E-Mail-Adresse existiert bereits."
            );
          }
        } else {
          showToastError(err, "Fehler beim Erstellen des Accounts.");
        }
        return Promise.reject(err);
      });
  },
  deleteAccount({ commit }, account: UserAccount): Promise<string | void> {
    return removeAccount(account)
      .then((res) => {
        commit("removeAccount", account);
        return res;
      })
      .catch((err) => {
        showToastError(err, "Fehler beim Speichern der Änderungen.");
      });
  },
  editAccount(
    { commit },
    account: UserAccount
  ): Promise<Partial<UserAccount> | void> {
    return editAccount(account.id, account)
      .then(() => {
        commit("editAccount", account);
        showToastSuccess("Mitarbeiter aktualisiert.");
        return account;
      })
      .catch((err) => {
        showToastError(err, "Fehler beim Speichern der Änderungen.");
      });
  },

  fetchAccessLevels({ commit }): Promise<AccessLevel[] | void> {
    if (!state.accessLevels || !state.accessLevels.length) {
      return fetchAccessLevels()
        .then((accessLevels: AccessLevel[]) => {
          commit("setAccessLevels", accessLevels);
          return accessLevels;
        })
        .catch((err) => {
          showToastError(err, "Fehler beim Laden der Zugriffsrechte");
        });
    } else {
      return Promise.resolve(state.accessLevels);
    }
  },

  /* Companies */
  fetchCompanies({ commit }): Promise<Company[] | void> {
    if (!state.companies || !state.companies.length) {
      return fetchCompanies()
        .then((companies: Company[]) => {
          commit("setCompanies", companies);
          return companies;
        })
        .catch((err) => {
          showToastError(err, "Fehler beim Laden der Unternehmen.");
        });
    } else {
      return Promise.resolve(state.companies);
    }
  },
  createCompany({ commit }, company: Company) {
    return createCompany(company).then((newCompany) => {
      commit("addCompany", newCompany);
      showToastSuccess("Neues Unternehmen angelegt.");
      return newCompany;
    });
  },
  editCompany({ commit }, company: Partial<Company>) {
    return editCompany(company).then(() => {
      showToastSuccess("Unternehmen aktualisiert.");
      commit("updateCompany", company);
    });
  },
  deleteCompany({ commit }, company: Company) {
    return removeCompany(company)
      .then(() => {
        commit("removeCompany", company);
      })
      .catch((err) => {
        showToastError(err, "Fehler beim löschen des/der Unternehmen.");
      });
  },

  /* Roles */
  fetchRole(_, roleId: string): Promise<Role | void> {
    return fetchRole(roleId)
      .then((role: Role) => {
        return role;
      })
      .catch((err) => {
        showToastError(err, "Fehler beim Laden der Rolle " + roleId);
      });
  },
  fetchRoles({ commit }): Promise<Role[]> {
    if (!state.roles || !state.roles.length) {
      return fetchRoles()
        .then((roles: Role[]) => {
          commit("setRoles", roles);
          return roles;
        })
        .catch((err) => {
          showToastError(err, "Fehler beim Laden der Rollen ");
          return [];
        });
    } else {
      return Promise.resolve(state.roles);
    }
  },
  createRole({ commit }, role: Partial<Role>) {
    return createRole(role).then(() => {
      commit("addRole", role);
    });
  },
  editRole({ commit }, role: Partial<Role>) {
    return editRole(role).then((newRole) => {
      commit("updateRole", newRole);
    });
  },
  deleteRole({ commit }, id: string) {
    return deleteRole(id).then(() => {
      commit("removeRole", id);
    });
  },

  /* Routes */
  fetchRoutes({ state, commit }): Promise<Route[]> {
    if (!state.routes || !state.routes.length) {
      return fetchRoutes().then((routes: Route[]) => {
        routes = orderBy(routes, ["route", "name"], ["asc"]);
        commit("setRoutes", routes);
        return routes;
      });
    } else {
      return new Promise((resolve) => resolve(state.routes));
    }
  },

  fetchModule(_, id: string): Promise<RbacModule | void> {
    return fetchModule(id)
      .then((module: RbacModule) => {
        return module;
      })
      .catch((err) => {
        showToastError(err, "Fehler beim Laden der Module ");
      });
  },
  fetchModules({ state, commit }): Promise<RbacModule[]> {
    if (!state.modules || !state.modules.length) {
      return fetchModules()
        .then((modules: RbacModule[]) => {
          commit("setModules", modules);
          return modules;
        })
        .catch((error) => {
          showToastError(error, "Fehler beim Laden der Module");
          return Promise.reject(error);
        });
    } else {
      return new Promise((resolve) => resolve(state.modules));
    }
  },
  createModule({ commit }, module: RbacModule): Promise<RbacModule> {
    return createModule(module)
      .then((module: RbacModule) => {
        commit("addModule", module);
        return module;
      })
      .catch((error) => {
        showToastError(error, "Fehler beim Erstellen des Moduls");
        return Promise.reject(error);
      });
  },
  editModule({ commit }, module: Partial<RbacModule>): Promise<RbacModule> {
    return editModule(module)
      .then((module: RbacModule) => {
        //Update state with edited object
        commit("updateModule", module);
        return module;
      })
      .catch((error) => {
        showToastError(error, "Fehler beim Bearbeiten des Moduls");
        return Promise.reject(error);
      });
  },
  deleteModule({ commit }, id: string) {
    return deleteModule(id)
      .then(() => {
        commit("removeModule", id);
      })
      .catch((error) => {
        if (
          error.response.data.error &&
          error.response.data.error === "SequelizeForeignKeyConstraintError"
        ) {
          showToastError(error, "Modul wird noch verwendet");
        } else {
          showToastError(error, "Fehler beim Löschen des Moduls");
        }
        return Promise.reject(error);
      });
  },

  fetchDuties({ state, commit }) {
    if (!state.duties || !state.duties.length) {
      return fetchDuties()
        .then((duties: Duty[]) => commit("setDuties", duties))
        .catch((error) => {
          showToastError(error, "Fehler beim Laden der Funktionen ");
          return Promise.reject(error);
        });
    } else {
      return new Promise((resolve) => resolve(state.duties));
    }
  },
  createDuty({ commit }, duty: Duty): Promise<Duty> {
    return createDuty(duty)
      .then((duty) => {
        commit("addDuty", duty);
        return Promise.resolve(duty);
      })
      .catch((error) => {
        showToastError(error, "Fehler beim Erstellen der Mitarbeiter-Funktion");
        return Promise.reject(error);
      });
  },
  editDuty({ commit }, duty: Duty): Promise<Duty> {
    return editDuty(duty)
      .then((duty) => {
        commit("setDuty", duty);
        return Promise.resolve(duty);
      })
      .catch((error) => {
        showToastError(
          error,
          "Fehler beim Bearbeiten der Mitarbeiter-Funktion"
        );
        return Promise.reject(error);
      });
  },
  deleteDuty({ commit }, duty: Duty): Promise<void> {
    return deleteDuty(duty)
      .then(() => commit("removeDuty", duty))
      .catch((error) => {
        showToastError(error, "Fehler beim Löschen der Mitarbeiter-Funktion");
        return Promise.reject(error);
      });
  },

  fetchUseCases({ commit }) {
    return fetchUseCases().then((useCases: UseCase[]) =>
      commit("setUseCases", useCases)
    );
  },
};

const mutations: MutationTree<UserManagement> = {
  setAccounts(state, accounts: UserAccount[]) {
    state.accounts = accounts;
  },
  addAccount(state, account: UserAccount) {
    state.accounts.push(account);
  },
  removeAccount(state, account: UserAccount) {
    const i = state.accounts.findIndex((acc) => acc.id === account.id);
    if (i > -1) state.accounts.splice(i, 1);
  },
  editAccount(state, account: UserAccount) {
    const i = state.accounts.findIndex((acc) => acc.id === account.id);
    if (i > -1) Object.assign(state.accounts[i], account);
  },

  setAccessLevels(state, accessLevels: AccessLevel[]) {
    state.accessLevels = accessLevels;
  },
  setCompanies(state, companies: Company[]) {
    //sort companies by name using lodash
    state.companies = sortBy(companies, "name");
  },
  addCompany(state, company: Company) {
    state.companies.push(company);
  },
  updateCompany(state, company: Partial<Company>) {
    const companyIndex = state.companies.findIndex(
      (obj) => obj.id === company.id
    );
    if (!companyIndex) return;
    Object.assign(state.companies[companyIndex], company);
  },
  removeCompany(state, company: Partial<Company>) {
    const i = state.companies.findIndex((obj) => obj.id === company.id);
    if (i) state.companies.splice(i, 1);
  },

  setDuties(state, duties: Duty[]) {
    state.duties = duties;
  },
  addDuty(state, duty: Duty) {
    state.duties.push(duty);
  },
  setDuty(state, duty: Duty) {
    const i = state.duties.findIndex((obj) => obj.id === duty.id);
    if (i > -1) Object.assign(state.duties[i], duty);
  },
  removeDuty(state, duty: Duty) {
    const i = state.duties.findIndex((obj) => obj.id === duty.id);
    if (i > -1) state.duties.splice(i, 1);
  },

  setUseCases(state, useCases: UseCase[]) {
    state.useCases = useCases;
  },
  setRoles(state, roles: Role[]) {
    state.roles = roles;
  },
  setRoutes(state, routes: Route[]) {
    state.routes = routes;
  },
  addRoute(state, route: Route) {
    state.routes.push(route);
  },
  addRole(state, role: Role) {
    state.roles.push(role);
  },

  updateRole(state, role: Partial<Role>) {
    const i = state.roles.findIndex((obj) => obj.id === role.id);
    if (i > -1) Object.assign(state.roles[i], role);
  },
  removeRole(state, id: string) {
    const i = state.roles.findIndex((role) => role.id === id);
    if (i > -1) state.roles.splice(i, 1);
  },
  updateRoute(state, route: Partial<Route>) {
    const i = state.routes.findIndex((obj) => obj.id === route.id);
    if (i > -1) Object.assign(state.routes[i], route);
  },

  setModules(state, modules: RbacModule[]) {
    state.modules = modules;
  },
  addModule(state, module: RbacModule) {
    state.modules.push(module);
  },
  updateModule(state, module: Partial<RbacModule>) {
    const i = state.modules.findIndex((obj) => obj.id === module.id);
    if (i > -1) Object.assign(state.modules[i], module);
  },
  removeModule(state, id: string) {
    const i = state.modules.findIndex((obj) => obj.id === id);
    if (i > -1) state.modules.splice(i, 1);
  },
};

const userManagement: Module<UserManagement, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
export default userManagement;
