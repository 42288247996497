import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { Columns, RootState } from "@/store/types";
import { find } from "lodash";
import { vehicleStatuses } from "@/assets/settings/statuses";
import { Column } from "@/types/recycling-manager/column";
import {
  replaceById,
  showToastError,
  showToastSuccess,
} from "@/plugins/utilities";
import {
  createColumn,
  deleteMultiple,
  editColumn,
  fetchColumns,
  updateEmployeesByColumnId,
} from "@/api/server";
import { UserAccount } from "@/types/user-management/user-account";
import { Department } from "@/types/recycling-manager/department";

const initialState: Columns = {
  columns: [] as Column[],
  statuses: vehicleStatuses,
};

const state: Columns = {
  ...initialState,
};

const getters: GetterTree<Columns, RootState> = {
  getStatus: (state) => (id: string | number) => {
    return find(state.statuses, { id: id });
  },
  getColumn: (state) => (id: string | number) => {
    return find(state.columns, { id: id });
  },
};

const actions: ActionTree<Columns, RootState> = {
  fetchColumns({ commit }): Promise<Partial<Column>[]> {
    if (state.columns && state.columns.length)
      return Promise.resolve(state.columns);
    return fetchColumns()
      .then((columns) => {
        commit("setColumns", columns);
        return Promise.resolve(columns);
      })
      .catch((e) => {
        showToastError(e, "Fehler beim laden der Kolonnen");
        return state.columns;
      });
  },
  createColumn(
    { commit },
    column: Partial<Column>
  ): Promise<Partial<Column> | void> {
    const col = {
      ...column,
      departments: column.departments?.map((d) => (d as Department).id),
    } as Column;

    return createColumn(col)
      .then(() => {
        commit("addColumn", column);
        return Promise.resolve(column);
      })
      .catch((e) => {
        showToastError(e, "Fehler beim erstellen der Kolonne");
      });
  },
  editColumn({ commit }, column: Column): Promise<Column> {
    const col = {
      ...column,
      departments: column.departments?.map((d) => (d as Department).id),
    } as Column;

    return editColumn(col)
      .then((col) => {
        showToastSuccess("Kolonne aktualisiert");
        commit("updateColumn", column);
        return Promise.resolve(col);
      })
      .catch((e) => {
        showToastError(e, "Fehler beim speichern der Kolonne");
        return Promise.reject(e);
      });
  },
  deleteColumns({ commit }, columns: Column[]) {
    deleteMultiple(process.env.VUE_APP_ASSET_ || "", "columns", columns)
      .then(() => {
        commit("removeColumns", columns);
      })
      .catch((e) => {
        showToastError(e, "Fehler beim Löschen der Kolonnen");
        throw e;
      });
  },
  editColumnEmployees(
    { commit },
    { columnId, employees }
  ): Promise<Partial<UserAccount>[]> {
    const ids = employees.map((e: UserAccount) => e.id);
    return updateEmployeesByColumnId(columnId, ids)
      .then((col) => {
        showToastSuccess("Mitarbeiter aktualisiert");
        commit("updateColumn", col);
        return Promise.resolve(col);
      })
      .catch((e) => {
        showToastError(e, "Fehler beim speichern der Kolonne");
        return Promise.reject(e);
      });
  },
};

const mutations: MutationTree<Columns> = {
  setColumns(state, columns: Column[]) {
    state.columns = columns;
  },
  addColumn(state, column: Column) {
    state.columns.push(column);
  },
  updateColumn(state, column: Column) {
    replaceById(state.columns, column, column.id as string);
  },
  removeColumns(state, columns: Column[]) {
    state.columns = state.columns.filter(
      (column) => !columns.some((c) => c.id === column.id)
    );
  },
};

const columns: Module<Columns, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
export default columns;
