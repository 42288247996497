
import Participation from "@/views/misc/CitizenParticipationForm.vue";
import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";

/**
 * Main entry point for the citizen participation app.
 * This component is used as the root component for the citizen router.
 */
@Component({
  components: { Participation },
})
export default class CitizenParticipation extends Vue {}
