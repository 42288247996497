import { FenceProperties, RootState } from "@/store/types";
import FenceType from "@/types/fence/fence-type";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

import { FenceTag } from "@/types/general/general";
import { fetchFenceTags, fetchFenceType } from "@/api/server";

const state: FenceProperties = {
  tags: [] as FenceTag[],
  types: [] as FenceType[],
};

const getters: GetterTree<FenceProperties, RootState> = {
  getFenceTypes(state) {
    return state.types;
  },
};

const actions: ActionTree<FenceProperties, RootState> = {
  async fetchTypes({ commit }) {
    if (state.types.length > 0) return;
    const fenceTypes = await fetchFenceType();
    commit("setTypes", fenceTypes);
  },
  async fetchTags({ commit }) {
    const fenceTags = await fetchFenceTags();
    commit("setTags", fenceTags);
  },
};

const mutations: MutationTree<FenceProperties> = {
  setTypes(state, fenceTypes: FenceType[]) {
    state.types = fenceTypes;
  },
  setTags(state, fenceTags: FenceTag[]) {
    state.tags = fenceTags;
  },
};

const fenceProperties: Module<FenceProperties, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
export default fenceProperties;
