import Vue from "vue";
import Vuex from "vuex";
import ApiKey from "@/types/api-key";
import Asset, { AssetFilter } from "@/types/asset/asset";
import Page, { PageInfo } from "@/types/page";
import ContentMaterialLogs from "@/types/asset/content-material-logs";

import userManagement from "@/store/modules/userManagement";
import user from "@/store/modules/user";
import asset from "@/store/modules/asset";
import company from "@/store/modules/company";
import assetProperties from "@/store/modules/assetProperties";
import columns from "@/store/modules/columns";
import vehicleProperties from "@/store/modules/vehicleProperties";
import fenceProperties from "@/store/modules/fenceProperties";
import pageActions from "@/store/modules/pageActions";
import reports from "@/store/modules/reports";
import avalCatalogStore from "@/store/modules/avalCatalog";
import { AvalCatalogStore, CitizenReports, RootState } from "@/store/types";
import Fence from "@/types/fence/fence";
import { find } from "lodash";
import { showToastError } from "@/plugins/utilities";
import { Department } from "@/types/recycling-manager/department";
import { UserAccount } from "@/types/user-management/user-account";
import {
  createDepartment,
  deleteDepartment,
  editDepartment,
  editFence,
  fetchApiKeys,
  fetchAssets,
  fetchContentMaterialLogs,
  fetchDepartments,
  fetchFenceByName,
  fetchFences,
} from "@/api/server";
import jobs from "@/store/modules/jobs";

Vue.use(Vuex);

export default new Vuex.Store<RootState>({
  state: {
    apiKeys: [] as ApiKey[],
    assets: {} as Page<Asset>,
    fences: [] as Fence[],
    departments: [] as Department[],
    contentMaterialLogs: [] as ContentMaterialLogs[],
    user: {} as UserAccount, // to enable type checking on RootState, initialized during runtime
    avalCatalog: {} as AvalCatalogStore, // to enable type checking on RootState, initialized during runtime
    reports: {} as CitizenReports, // to enable type checking on RootState, initialized during runtime
  },
  getters: {
    assets: (state) => {
      return state.assets.data ? state.assets.data : ([] as Asset[]);
    },
    assetById: (state) => (id: string) => {
      return find(state.assets.data, { id: id });
    },
    assetsPagination: (state) => {
      return state.assets.pagination
        ? state.assets.pagination
        : ({} as PageInfo);
    },
    apiKeys: (state) => state.apiKeys,
    getDepartment: (state) => (id: string) => {
      return find(state.departments, { id: id });
    },
    getDepartments: (state) => (ids: string[]) => {
      if (!ids || !state.departments) return [];
      return state.departments.filter((dep) => {
        return ids.includes(dep.id as string);
      });
    },
    getFence: (state) => (id: string) => {
      return find(state.fences, { id: id });
    },
  },

  actions: {
    fetchApiKeys({ state, commit }) {
      if (state.apiKeys && state.apiKeys.length)
        return Promise.resolve(state.apiKeys);
      return fetchApiKeys().then((apiKeys: ApiKey[]) =>
        commit("setKeys", apiKeys)
      );
    },
    fetchAssets({ commit }, filter: AssetFilter) {
      return fetchAssets(filter)
        .then((assets: Page<Asset>) => commit("setAssets", assets))
        .catch((error) => {
          showToastError(error, "Fehler beim Laden der Behälter");
        });
    },
    fetchFences({ commit }, force = false) {
      if (!force && this.state.fences && this.state.fences.length) {
        return Promise.resolve(this.state.fences);
      } else {
        return fetchFences()
          .then((fences: Fence[]) => commit("setFences", fences))
          .catch((error) => {
            showToastError(error, "Fehler beim Laden der Behälter");
          });
      }
    },
    fetchFencesByName({ commit }, name: string) {
      return fetchFenceByName(name).then((fences: Fence[]) =>
        commit("setFences", fences)
      );
    },
    fetchContentMaterialLogs(context, params) {
      return fetchContentMaterialLogs(params.fenceId).then(
        (contentMaterialLogs: ContentMaterialLogs[]) =>
          context.commit("setContentMaterialLogs", contentMaterialLogs)
      );
    },
    editFence({ commit, state }, partialFence: Partial<Fence>) {
      const newFence = partialFence as Fence;
      return editFence(newFence).then(() => {
        const newFences = state.fences.map((fence) => {
          return fence.id === newFence.id ? newFence : fence;
        });
        commit("setFences", newFences);
      });
    },
    fetchDepartments({ state, commit }): Promise<Department[]> {
      if (state.departments && state.departments.length)
        return Promise.resolve(state.departments);
      return fetchDepartments()
        .then((departments) => {
          commit("setDepartments", departments);
          return departments;
        })
        .catch((e) => {
          showToastError(e, "Fehler beim laden der Abteilungen");
          return state.departments;
        });
    },
    createDepartment({ commit }, department: Department) {
      return createDepartment(department)
        .then((newDepartment) => {
          commit("addDepartment", newDepartment);
          return newDepartment;
        })
        .catch((error) => {
          showToastError(error, "Fehler beim erstellen der Abteilungen");
          return Promise.reject(error);
        });
    },
    editDepartment({ commit }, department: Department) {
      return editDepartment(department)
        .then(() => commit("editDepartment", department))
        .catch((error) => {
          showToastError(error, "Fehler beim erstellen der Abteilungen");
          return Promise.reject(error);
        });
    },
    deleteDepartment({ commit }, department: Department) {
      return deleteDepartment(department)
        .then(() => commit("removeDepartment", department))
        .catch((error) => {
          showToastError(error, "Fehler beim löschen der Abteilung");
        });
    },
  },

  mutations: {
    setKeys(state, apiKeys) {
      state.apiKeys = apiKeys;
    },
    setAssets(state, assets) {
      state.assets = assets;
    },
    setFences(state, fences: Fence[]) {
      state.fences = fences;
    },
    setContentMaterialLogs(state, contentMaterialLogs) {
      state.contentMaterialLogs = contentMaterialLogs;
    },

    setDepartments(state, departments: Department[]) {
      state.departments = departments;
    },
    addDepartment(state, department: Department) {
      state.departments.push(department);
    },
    editDepartment(state, department: Department) {
      const i = state.departments.findIndex((obj) => obj.id === department.id);
      if (i > -1) Object.assign(state.departments[i], department);
    },
    removeDepartment(state, duty: Department) {
      const i = state.departments.findIndex((obj) => obj.id === duty.id);
      if (i > -1) state.departments.splice(i, 1);
    },
  },

  modules: {
    userManagement,
    user,
    asset,
    assetProperties,
    vehicleProperties,
    fenceProperties,
    pageActions,
    columns,
    company,
    reports,
    jobs,
    avalCatalog: avalCatalogStore,
  },
});
