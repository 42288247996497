import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { AvalCatalogStore, RootState } from "@/store/types";
import {
  fetchAvalCatalogs,
  fetchAvalContainerTypes,
  fetchAvalCycles,
  fetchAvalDocumentTypes,
  fetchAvalIncidents,
  fetchAvalMaterials,
  fetchAvalServices,
  fetchAvalUnits,
} from "@/api/server";
import { AxiosError } from "axios";
import { showToastWarning } from "@/plugins/utilities";
import { AvalCatalog, AvalCatalogEntry } from "@/types/aval/aval_catalog";

const state: AvalCatalogStore = {
  catalogs: [] as AvalCatalog[],
  units: [] as AvalCatalogEntry[],
  services: [] as AvalCatalogEntry[],
  materials: [] as AvalCatalogEntry[],
  containerTypes: [] as AvalCatalogEntry[],
  cycles: [] as AvalCatalogEntry[],
  incidents: [] as AvalCatalogEntry[],
  documentTypes: [] as AvalCatalogEntry[],
};

const getters: GetterTree<AvalCatalogStore, RootState> = {
  //e.g. jede(n), jede(n) 2., jede(n) 3.
  cycleIntervalAmounts: (state) => {
    const cycleAvalCatalog = state.cycles.find((c) => c.pos === 1);
    return cycleAvalCatalog?.catalogData ?? [];
  },
  cycleIntervalUnits: (state) => {
    const cycleAvalCatalog = state.cycles.find((c) => c.pos === 2);
    return cycleAvalCatalog?.catalogData ?? [];
  },
};

const actions: ActionTree<AvalCatalogStore, RootState> = {
  fetchCatalogs({ commit }) {
    if (state.catalogs.length === 0) {
      fetchAvalCatalogs()
        .then((response) => {
          commit("setCatalogs", response);
        })
        .catch((error: AxiosError) => {
          showToastWarning(error.message);
        });
    }
  },

  fetchUnits({ commit }) {
    if (state.units.length === 0) {
      fetchAvalUnits()
        .then((response) => {
          commit("setUnits", response);
        })
        .catch((error: AxiosError) => {
          showToastWarning(error.message);
        });
    }
  },

  fetchServices({ commit }) {
    if (state.services.length === 0) {
      fetchAvalServices()
        .then((response) => {
          commit("setServices", response);
        })
        .catch((error: AxiosError) => {
          showToastWarning(error.message);
        });
    }
  },

  fetchMaterials({ commit }) {
    if (state.materials.length === 0) {
      fetchAvalMaterials()
        .then((response) => {
          commit("setMaterials", response);
        })
        .catch((error: AxiosError) => {
          showToastWarning(error.message);
        });
    }
  },

  fetchContainerTypes({ commit }) {
    if (state.containerTypes.length === 0) {
      return fetchAvalContainerTypes()
        .then((response) => {
          commit("setContainerTypes", response);
          return response;
        })
        .catch((error: AxiosError) => {
          showToastWarning(error.message);
        });
    } else {
      return Promise.resolve(state.containerTypes);
    }
  },

  fetchCycles({ commit }) {
    if (state.cycles.length === 0) {
      fetchAvalCycles()
        .then((response) => {
          commit("setCycles", response);
        })
        .catch((error: AxiosError) => {
          showToastWarning(error.message);
        });
    }
  },

  fetchIncidents({ commit }) {
    if (state.incidents.length === 0) {
      fetchAvalIncidents()
        .then((response) => {
          commit("setIncidents", response);
        })
        .catch((error: AxiosError) => {
          showToastWarning(error.message);
        });
    }
  },

  fetchDocumentTypes({ commit }) {
    if (state.documentTypes.length === 0) {
      fetchAvalDocumentTypes()
        .then((response) => {
          commit("setDocumentTypes", response);
        })
        .catch((error: AxiosError) => {
          showToastWarning(error.message);
        });
    }
  },
};

const mutations: MutationTree<AvalCatalogStore> = {
  setCatalogs(state, catalogs: AvalCatalog[]) {
    state.catalogs = catalogs;
  },
  setUnits(state, units: AvalCatalogEntry[]) {
    state.units = units;
  },
  setServices(state, services: AvalCatalogEntry[]) {
    state.services = services;
  },
  setMaterials(state, materials: AvalCatalogEntry[]) {
    state.materials = materials;
  },
  setContainerTypes(state, containerTypes: AvalCatalogEntry[]) {
    state.containerTypes = containerTypes;
  },
  setCycles(state, cycles: AvalCatalogEntry[]) {
    state.cycles = cycles;
  },
  setIncidents(state, incidents: AvalCatalogEntry[]) {
    state.incidents = incidents;
  },
  setDocumentTypes(state, documentTypes: AvalCatalogEntry[]) {
    state.documentTypes = documentTypes;
  },
};

const avalCatalogStore: Module<AvalCatalogStore, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

/**
 * AvalCatalogStore stores all the catalogs used in the Aval Standard.
 * For more information, see the [Aval Stammdaten](https://www.avalstandard.de/stammdaten/).
 */
export default avalCatalogStore;
