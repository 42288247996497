import Company from "@/types/company";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "@/store/types";
import { fetchCompanyById } from "@/api/server";

const initialState: Company = {
  id: "",
  name: "",
  street: "",
  houseNumber: "",
  zip: "",
  city: "",
  useCaseID: "",
  //base64 encoded image
  logo: "",
  companyType: {
    isProducer: null,
    isDisposer: null,
    isCarrier: null,
    isRemover: null,
  },
};

const state: Company = {
  ...initialState,
};

const getters: GetterTree<Company, RootState> = {};

const actions: ActionTree<Company, RootState> = {
  fetchById({ commit }, id: string): Promise<void | Company> {
    return fetchCompanyById(id)
      .then((company) => {
        commit("setCompany", company);
        return company;
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  },
};

const mutations: MutationTree<Company> = {
  setCompany(state, company: Company) {
    Object.assign(state, company);
  },
};

const company: Module<Company, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
export default company;
